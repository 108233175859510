.Requests {
  width: 100%;
  margin-top: 80px;
  transition: 0.2s all;
}
.buttonFirst {
  margin-right: 20px;
  width: 180px;
}
.button {
  width: 180px;
}
.title {
  margin-top: 50px;
  font-family: Montserrat, Rubik;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 48px;
  width: 100%;
}
.wrapper {
  display: flex;
  margin-top: 40px;
}
.leftText {
  width: 80%;
  font-size: 24px;
  line-height: 37px;
}
.leftButton {
  width: 259px;
  height: 60px;
  line-height: 60px;
  background: #5d36bd;
  text-align: center;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 700;
  margin-top: -60px;
  color: white;
  position: relative;
  z-index: 2;
}
.right,
.left {
  width: 50%;
  position: relative;
}
.right {
  position: relative;
  top: -20px;
}
.row {
  width: 50%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  float: left;
}
.image {
  height: 60px;
  width: 60px;
  border-radius: 18px;
  background: rgba(219, 202, 254, 0.2);
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.items {
  margin-top: 80px;
  display: flex;
  overflow-y: hidden;
  height: auto;
}
.items_wrapper {
  display: flex;
}
.items_wrapperCenter {
  justify-content: center;
}
.showInDesktop {
  display: block;
}
.appsText {
  width: 100%;
  font-size: 24px;
  font-family: Montserrat, Rubik;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
}
.buttons {
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .items_wrapperCenter {
    justify-content: flex-start;
  }
  .showInDesktop {
    display: none;
  }
  .items_wrapper {
    width: auto;
    display: flex;
  }
  .Requests {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }
  .wrapper {
    display: block;
    margin-top: 0px;
  }
  .left,
  .right {
    width: 100%;
    margin-top: 0px;
  }
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: -0.4px;
    text-align: left;
    margin-top: 0px;
  }
  .items {
    width: calc(100% + 20px);
    margin-top: 40px;
    flex-wrap: wrap;
    overflow: scroll;
  }
  .row {
    width: 100%;
  }
  .leftButton {
    width: 100%;
    margin-top: 30px;
  }
  .leftText {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.44px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .leftButton {
    z-index: 1;
  }
  .buttons,
  .appsText {
    display: none;
  }
}
.heb {
  .title {
    text-align: right;
    font-family: 'Rubik', sans-serif;
  }
  .leftText {
    width: 90%;
    direction: rtl;
    text-align: right;
    float: right;
  }
  .leftButton {
    float: right;
  }
  .row {
    // flex-direction: row-reverse;
  }
  .image {
    margin-left: 16px;
    margin-right: 0px;
  }
  .appsText {
    direction: rtl;
    font-family: 'Rubik', sans-serif;
  }
}
