.wrapper {
  padding: 40px 25px;
  padding-bottom: 30px;
  padding-top: 90px;
  overflow: hidden;
  width: 100%;
  max-width: 780px;
}
.input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-family: 'Assistant';
  line-height: 18px;
  letter-spacing: -0.0041em;
}
.inputElement {
  width: calc(100% - 120px);
  outline: none;
  border: none;
  border-bottom: 1px rgba(93, 54, 189, 0.3) solid;
  padding-bottom: 10px;

  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
}
.button {
  width: 181px;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  padding: 13px 47px;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  border-radius: 18px;
  text-align: center;
  background: #5d36bd;
  color: white;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}
.heb {
  direction: rtl;

  .button {
    left: initial;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}
