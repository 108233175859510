@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/scss/default/theme';

.VerificationScreen {
  height: '100%';

  .title {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */

    letter-spacing: -0.41px;

    color: #111111;

    &.rtl {
      text-align: right;
    }
  }

  .subtitle {
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.31px;

    /* basic black 50% */

    color: #888888;
    &.rtl {
      text-align: right;
    }
  }

  .button {
    font-family: Assistant !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    /* identical to box height */

    text-align: center;
    border-radius: 21px !important;
    letter-spacing: -0.165px !important;
  }

  .input {
    border-color: rgba(93, 54, 189, 0.3) !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-family: Assistant !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: -0.165px !important;
  }

  .checkbox {
    padding: 0 !important;
    display: flex !important;

    &.rtl {
      input {
        & + label::before {
          margin-right: auto;
          margin-left: 20px;
        }
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      & + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-family: Assistant;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 130% !important;
        /* or 18px */

        letter-spacing: -0.165px;
      }
      & + label::before {
        content: '';
        display: inline-block;
        border: 2px solid #dbcafe;
        box-sizing: border-box;
        border-radius: 5px;
        min-width: 30px;
        height: 30px;
        margin-right: 20px;
      }
      &:checked + label::before {
        background: #dbcafe url('../following_checkmark.svg') center center
          no-repeat;
      }
    }
  }

  .bottom {
    left: 0px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-family: Assistant !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 120% !important;
    /* identical to box height, or 22px */
    letter-spacing: -0.41px !important;
  }

  .loader {
    display: inline-block;
    position: relative;
    margin: auto;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    margin: -10px -20px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      border: 4px solid #fff;
      top: 50%;
      left: calc(50% - 15px);
      border-radius: 50%;
      animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #a7a7a7 transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}

.NewSignupWelcomeScreen {
  position: relative;
  width: 100%;
  height: 100%;

  .imgContainer {
    height: 75% !important;

    img {
      height: 100%;
    }
  }

  .assetContainer {
    display: contents;
    text-align: center;
    flex-direction: column;
  }

  .title {
    padding-top: 0px !important;
    text-align: center;
    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.41px;

    color: #5d36bd;

    &.rtl {
      text-align: right;
      font-family: 'Rubik', sans-serif;
    }
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media #{$media-phone} {
  .NewSignupWelcomeScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    img {
      min-height: 65vh;
      height: 65vh;
    }

    .title {
      padding-top: 0px !important;
      text-align: center;
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.41px;

      color: #5d36bd;

      &.rtl {
        text-align: right;
        font-family: 'Rubik', sans-serif;
      }
    }
  }
}
