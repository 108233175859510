.wrapper {
  padding: 20px;
  min-height: 100vh;
  padding-top: 90px;
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  margin: 20px 0px;
}
.heb {
  direction: rtl;
}
