.Main {
  width: 100%;
  height: 100vh;
  position: relative;
  transition: 0.4s opacity;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 60px;
}
.MainWrapper {
  max-width: 1240px;
  width: 100%;
  height: 95%;
}
.Main_right,
.Main_left {
  width: 50%;
  position: relative;
}
.Main_right {
  width: auto;
  display: flex;
  justify-content: flex-end;
}
.scrollDown {
  cursor: pointer;
  transition: 2s all;
  animation: sdb04 2s infinite;
  animation-delay: 1s;
  position: absolute;
  bottom: 25px;
  right: calc((100vw - 1240px) / 2);
}
@keyframes sdb04 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0px, 10px);
  }
  40% {
    transform: translate(0, 0);
  }
}
.hideInDesktop {
  display: none;
}
.mainImage {
  border-radius: 26px;
  height: 100%;
  overflow: hidden;
  outline: none;
  transition: 0.2s all;
  position: relative;

  span {
    border-radius: 0px;
  }

  video,
  .mainImageNoVideo {
    width: 100px;
    height: calc(75vh);
    max-height: 703px;
    min-width: 527px;
    object-fit: cover;
    outline: none;
    cursor: pointer;
    border-radius: 30px;
    position: relative;
    float: right;
  }
}
.sound {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  color: black;
  position: absolute;
  bottom: 14px;
  right: 14px;
}
.loveIcon {
  width: 60px;
  cursor: pointer;
  position: relative;
  top: 4px;
  left: 3px;
}
.regularIcon {
  width: 28px;
}

.Main_left_url {
  font-size: 16px;
  color: white;
  font-weight: 300;
  a,
  a:hover {
    color: rgba(255, 255, 255, 0.6);
  }

  span {
    color: white;
    font-weight: bold;
  }
}
.Main_left_Profile {
  width: 100%;
  margin-top: -30px;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}
.Main_left_Profile_image {
  display: flex;
  align-items: center;
}
.Main_left_Profile_imageImg {
  width: 60px;
  height: 60px;
  border-radius: 25%;
  background: rgb(150, 150, 150);
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.Main_left_Profile_imageText {
  font-weight: 500;
  margin-left: 20px;
  display: flex;

  .Main_left_Profile_imageText_hash {
    direction: ltr;
  }
}
.Main_left_Profile_title {
  width: 130%;
  font-size: 76px;
  margin-top: 10px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -0.4px;
  font-family: Montserrat, Rubik;
}
.Main_left_Profile_SubTitle {
  font-weight: bold;
  font-size: 24px;
  color: #c8cacf;
  margin-top: 8px;
  font-weight: 700;
}
.Main_left_Buttons {
  display: flex;
  width: 100%;
  justify-content: left;
  margin-top: 40px;
  position: relative;
  // bottom: 40px;
}
.Main_left_Buttons_button {
  width: 200px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 16px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  border: 2px #dbcafe solid;
  transition: 0.2s all;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 6px 16px rgb(40, 40, 40);
    transform: scale(1.05);
  }
}
.Main_left_Buttons_buttonMain {
  margin-right: 0px !important;
  background: #5d36bd;
  border: none;

  &:hover {
    background: #5d36bd;
  }
}
.Main_left_Buttons_buttonSecondary {
  background: var(--primaryLight) !important;
  margin-right: 0px !important;
  border: white;
  color: var(--primary);

  &:hover {
    background: #5d36bd;
  }
}
.Main_links {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Main_links_link {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 18px;
  margin-right: 8px;
  margin-top: 40px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: 0.2s all;
  color: white;

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.02);
  }
}
.star {
  width: 28px;
  margin-left: 15px;
}
.likes {
  display: none;
  // display:flex;
  margin-top: 25px;
}
.likes_box {
  margin-right: 50px;
  width: 80px;

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.4px;
  }
}
.Main_left_subscribe_button {
  margin-left: 10px;
  margin-right: 10px;
}
.forMobile {
  display: none;
}
.skeletonPosition {
  border-radius: 30px;
  overflow: hidden;
}
.Main_leftNoVideo {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .Main_left_Buttons_buttonSecondary {
    background: var(--primary) !important;
    color: white;

    &:hover {
      background: #5d36bd;
    }
  }
  .Main_left_Buttons_buttonMain {
    display: none;
  }
  .scrollDown {
    display: none;
  }
  .Main {
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    display: none !important;
  }
  .Main_right,
  .Main_left {
    width: 100%;
    display: none;
  }
  .likes {
    display: none;
  }
  .Main_left {
    margin-top: 480px;
  }
  .Main_leftNoVideo {
    margin-top: 60px;
  }
  .MainWrapper {
    height: auto;
    display: block;
  }
  .Main_left_url,
  .hideInMobile {
    display: none;
  }
  .mainImage {
    display: none !important;
    width: calc(100% + 40px);
    height: 580px;
    border-radius: 0px 0px 40px 40px;
    margin-top: 0px;
    position: fixed;
    left: -20px;
    top: 0px;

    video,
    .mainImageNoVideo {
      min-width: auto;
      border-radius: 0px 0px 40px 40px;
      float: initial;
    }
  }
  /* delete this if you want a full screen video*/
  .mainImage {
    margin-top: 80px;
    .skeletonPosition {
      transform: translateX(-50%);
      overflow: hidden;
      border-radius: 40px;
      position: relative;
      left: 50%;
    }
    video,
    .mainImageNoVideo {
      border-radius: 16px;
      width: 70px;
      height: 70px;
      transform: translateX(-50%);
      position: relative;
      left: 50%;
    }
  }
  .Main_left_Profile_imageImg {
    display: none;
  }
  /* until here */
  .mainImage,
  .mainImage img {
    border-radius: 0px 0px 40px 40px;
  }
  .Main_left_Profile_image {
    flex-direction: column;
  }
  .Main_left_Profile {
    transform: translateY(0%);
    margin-top: -50px;
    top: 0px;

    .Main_left_Profile_imageImg {
      width: 100px;
      height: 100px;
    }
  }
  .Main_left_Profile_imageText {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .Main_left_Profile_title {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-top: 7px;
    text-align: center;
  }
  .Main_left_Profile_SubTitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    font-family: 'Assistant';
    margin-top: 2px;
  }
  .Main_left_Buttons {
    margin-top: 0px;
    display: block;
    position: relative;
  }
  .Main_left_request_button {
    display: none !important;
  }

  .Main_left_ButtonsSmall {
    margin-top: 0px;
  }
  .Main_left_Buttons_button {
    width: 100%;
    margin-top: 10px;
  }
  .Main_right {
    display: block;
  }
  .Main_links {
    display: none;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .Main_links_link {
    margin-top: 10px;
  }
  .hideInDesktop {
    display: block;
  }
  .Main_left_Profile_imageText span {
    position: relative;
    top: 2px;
  }
  .star {
    margin-left: 8px;
  }
  .sound {
    background: none;
    margin-right: -114px;
    right: 50%;
    bottom: 64px;

    img {
      width: 30px;
      border-radius: 0px;
    }
  }
  .Main_left_subscribe_button {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sendReqMobile {
    display: none;
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    // background:rgba(38, 34, 52, 0.92);
    // box-shadow:inset 0 -80px 80px -80px rgb(14,9,30/);
    background: linear-gradient(
      180deg,
      rgba(14, 9, 30, 0) 35%,
      rgba(14, 9, 30, 0.9) 100%
    );
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 2000;
  }
  .Main_left_Profile_imageText {
    display: flex;
  }
}
.heb {
  .Main_left_Buttons_buttonSecondary {
    margin-right: 10px !important;
  }

  @media only screen and (max-width: 1024px) {
    .Main_left_Buttons_buttonSecondary {
      margin-right: 0px !important;
    }
  }

  .Main_left_Profile_title {
    font-family: 'Rubik', sans-serif;
  }
  .scrollDown {
    right: initial;
    left: 13px;
  }
  .Main_left_url {
    text-align: right;
  }
  .MainWrapper {
    flex-direction: row;
  }
  .Main_right {
    flex-direction: row-reverse;
  }
  .Main_left_Profile {
    direction: rtl;
    text-align: right;
  }
  .Main_left_Profile_title,
  .Main_left_Profile_SubTitle {
    direction: rtl;
  }
  .Main_left_Profile_imageText {
    margin-left: 0px;
    margin-right: 20px;
    display: flex;
  }
  .star {
    margin-left: 0px;
    margin-right: 15px;
  }
  .Main_left_Buttons {
    justify-content: flex-start;
  }
}
