@import '../../assets/scss/default/theme';
@import '../../assets/scss/default/mixins';

.image {
  opacity: 0;
  @include transition(all $fast $easing);
  padding: 0;
  margin: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.loaded {
  opacity: 1;
}

.blur {
  filter: blur(15px);
  object-fit: cover;
}
