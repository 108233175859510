.BookingBox {
  border: 2px solid #5d36bd;
  border-radius: 18px;
  padding: 20px;
  margin-top: 15px;
  position: relative;

  &:first-child {
    margin-top: 0px;
  }
}
.title {
  font-family: Montserrat, Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #5d36bd;
}
.profile {
  margin-top: 10px;
  align-items: center;
}
.input {
  width: 100%;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 6px;
  border: none;
  outline: none;
  color: black;
  border-bottom: 1px rgb(219, 202, 254) solid;
  background: none;
}
.checkbox {
  display: flex;
  align-items: right;
  flex-direction: column;
}
.image {
  width: 52px;
  height: 52px;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 10px;
  color: #5d36bd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.imageLoading {
  width: 62px;
  height: 60px;
}
.checkbox_text {
  width: 102px;
  color: rgb(137, 138, 141);
  cursor: pointer;
  font-family: Montserrat, Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  transition: 0.2s all;
  position: absolute;
  right: 15px;
  top: 22px;

  &:hover {
    color: black;
  }
}
.checkboxBootstrap {
  display: flex !important;
  justify-content: flex-end;
  transform: scale(1.7);
  margin-top: 25px;
  position: relative;
  right: 15px;
}
.error {
  color: red;
  font-size: 13px;
  position: absolute;
  left: 82px;
  bottom: 6px;
}
.shake {
  animation: shake 1s forwards ease-in;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-12px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}
.heb {
  .title {
    direction: rtl;
    text-align: right;
    font-family: 'Rubik', sans-serif;
  }
  .checkbox_text {
    width: 110px;
    font-family: 'Rubik', sans-serif;
    left: 19px;
    right: initial;
    text-align: left;
  }
  .input,
  .profile {
    direction: rtl;
    text-align: right;
  }
  .image {
    margin-right: 0px;
    margin-left: 10px;
  }
  .error {
    left: initial;
    right: 62px;
  }
}
@media only screen and (max-width: 1024px) {
  .BookingBox {
    border: 1px solid #5d36bd;
    padding: 10px 14px;
  }
  .title {
    font-size: 12px;
  }
  .error {
    left: 62px;
    bottom: 2px;
  }
  .text,
  .checkbox_text,
  .input {
    font-size: 12px;
  }
  .checkbox_text {
    top: 8px;
  }
  .image {
    width: 36px;
    height: 36px;
  }
  .checkbox_text {
    font-size: 12px;
    text-align: right;
  }
  .heb {
    .checkbox_text {
      text-align: left;
    }
  }
}
