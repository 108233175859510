.Success {
  color: black;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.successImage {
  width: 200px;
  margin-top: 60px;
}
.title {
  //styleName: Web/Small title 32px;
  font-family: Montserrat, Rubik;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #5d36bd;
  margin-top: 40px;
}
.heb {
  .title {
    font-family: 'Rubik';
  }
}
.text {
  max-width: 400px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-align: center;
  margin-top: 20px;
}
.images {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 60px;

  img {
    width: 180px;
  }
}
.downloadMobile {
  width: 240px;
  display: none;
}
.maybeLater {
  display: none;
}
.heb {
  .maybeLater {
    direction: rtl;
  }
}
.heb {
  .title,
  .text {
    direction: rtl;
  }
}

@media only screen and (max-width: 1024px) {
  .images {
    display: none;
  }
  .title {
    margin-top: 20px;
  }
  .successImage {
    width: 65%;
  }
  .downloadMobile {
    width: 240px;
    height: 62px;
    border-radius: 16px;
    display: block;
    background: #5d36bd;
    margin-top: 20px;
    padding: 0px 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: white;

    img {
      margin-left: 10px;
    }
  }

  .maybeLater {
    height: 62px;
    border-radius: 16px;
    display: block;
    margin-top: 20px;
    padding: 0px 20px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(17, 17, 17, 0.5);
  }
}
