.switcher {
  display: flex;
  align-items: center;

  button {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    font-family: Assistant, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
  }
}

.menu {
  right: 0;
  left: auto !important;
  box-shadow: 0 4px 16px rgba(0, 14, 36, 0.1);
}

.rtl {
  right: auto !important;
  left: 0 !important;
}

.sidebarSwitcher {
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #979797;
}

.active {
  // color: #5d36bd;
  color: white;
}

.dark {
  a {
    color: black;
  }

  .active {
    color: white;
  }
  // button:after{
  // TODO: need to fix it on light theme
  // background: url("../NewNavbar/arrow_white_down.svg") center center no-repeat;
  // }
}
