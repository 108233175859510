@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '../assets/scss/default/mixins';
@import '../assets/scss/default/theme';

.signupScreen {
}

.authContainer {
  display: grid;
  grid-template-columns: 1fr;

  @media #{$media-phone} {
    padding: 0 !important;
    margin: 0 !important;
    width: 100vw !important;
  }

  > header {
    text-align: center;
    margin-block-end: 30px;

    @media #{$media-phone} {
      width: 100vw;
      margin-block-end: 0;
    }

    figure {
      width: 220px;
      margin: 30px auto 15px;

      @media #{$media-phone} {
        margin: 0 auto;
        width: 33%;
        height: 60px;

        img {
          object-fit: contain;
        }
      }
    }

    h1 {
      font-family: $secondary-font;
      font-weight: normal;
      font-size: 24px;
      line-height: 120%;
      text-transform: capitalize;
      color: $black;
      padding-inline: 40px;

      @media #{$media-phone} {
        padding-inline: 0;
        display: none;
      }
    }
  }

  > main {
    padding-block-end: 50px;

    > div {
      margin: 0 auto;
      box-shadow: 8px 16px 36px rgba(0, 0, 0, 0.6);

      @media #{$media-phone} {
        box-shadow: none;
      }
    }
  }

  aside {
    //border: 1px solid #bdc123;
  }
}

.bg {
  background: none;
  @include media-breakpoint-up(lg) {
    background: url('large_bg.png') center center no-repeat !important;
    background-size: contain;
    min-height: 700px !important;
  }
  @include media-breakpoint-up(md) {
    background: url('medium_bg.png') center center no-repeat;
    background-size: contain;
  }
}

.wrapper {
  @include media-breakpoint-up(lg) {
    background: $white;
    border-radius: 30px;
    box-shadow: 8px 16px 36px rgba(118, 138, 149, 0.2) !important;
    min-height: 600px;
  }
  @include media-breakpoint-up(md) {
    background: none;
    box-shadow: none;
  }

  &.rtl {
    direction: rtl;
  }
}

@media only screen and (max-width: lg) {
}
@media only screen and (max-width: md) {
}
