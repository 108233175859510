.Main_right_icons{
    margin-left:30px;
}
.Main_right_icons_icon{
    width:60px;
    height:60px;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    border:1px rgba(255,255,255,0.2) solid;
    transition:0.2s all;
    cursor: pointer;

    &:hover{
        box-shadow: 0px 6px 16px rgb(40,40,40);
        border:1px rgba(255,255,255,0) solid;
        background:rgba(93,54,190,0.6);
        transform: scale(1.05);
    }
}
.Main_right_icons_iconLast{
    background:rgba(93,54,190,0.4);
    border:none;

    &:hover {
        border:none;
    }

    img{
        position:relative;
        top:2px;
        left:2px;
    }
}
.heb{
    margin-left:0px;        
    margin-right:30px;        
}
@media only screen and (max-width: 1024px) {
    .Main_right_icons{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left:0px;
        margin-top:20px;
    }
    .Main_right_icons_icon{
        width:60px;
        height:60px;
        margin-bottom:5px;
    }
    .Main_right_icons_iconLast{
        margin-left:14px;
    }
    .hideLove{
        width:100%;
        justify-content: center;
        .Main_right_icons_icon{
            margin-right:0px;
        }

        .Main_right_icons_icon:last-child{
            margin-right: 0px;
        }
    }

    .heb{
        margin-right:0px;

        .Main_right_icons_iconLast{
            margin-right: 14px;
            margin-left: 0px;
        }
    }
}