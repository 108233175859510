.countries {
  background: none !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  font-family: 'Assistant';

  outline: none;

  &:focus {
    box-shadow: none !important;
  }
}
.countries_text {
  font-weight: 600;
  font-size: 18px;
}
.countries_menu {
  width: 240px;
  background: rgb(62, 58, 75) !important;
  padding: 12px 0px !important;
  box-shadow: 0px 4px 16px rgba(0, 14, 36, 0.3);
}
.countries_text {
  margin: 0px 5px;
}
.countries_menu_item {
  align-items: center;
  padding: 6px 20px !important;
  font-family: 'Assistant';

  &:hover {
    color: white !important;
    background: rgba(0, 0, 0, 0.2) !important;
  }
}
.search {
  padding: 6px 20px;
  border-bottom: 1px #e3e3ee solid;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.searchInput {
  color: white;
  border: none;
  outline: none;
  font-family: 'Assistant';
  font-weight: 600;

  &::placeholder {
    font-weight: 600;
    color: #c8cacf;
  }
}
.searchIcon {
  margin-right: 10px;
}
.countries_flag {
  width: 20px;
  height: 20px;
  margin-right: 14px;
}
.countries_flag_main,
.countries_flag {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 1px;

  margin-left: 0px;
  margin-right: 6px;
  border-radius: 50%;
  overflow: hidden;

  svg {
    width: auto;
    height: 106%;
    transform: translate(-50%, -50%);
    position: relative;
    left: 50%;
    top: 50%;
  }
}
.clock {
  margin-left: 5px;
  margin-right: 5px;
}
.mainIcon2 {
  position: relative;
  top: 1px;
}
.noResults {
  font-family: 'Assistant';
  color: white;
  padding: 6px 20px;
}
.white {
  .countries_text {
    color: black;
  }
  .countries_menu_item {
    &:hover {
      color: black !important;
    }
  }
  .clock {
    color: black;
  }
  .countries {
    // &:after {
    //   border: none;
    //   background: url('../NewNavbar/dropdown.svg') center center no-repeat;
    //   margin: 0 10px;
    //   width: 10px;
    //   height: 5px;
    // }
  }
  .countries_menu {
    background: white !important;
  }
}
.heb {
  direction: rtl;

  .countries_flag,
  .searchIcon {
    margin-right: 0px;
    margin-left: 10px;
  }

  .noResults {
    direction: rtl;
    text-align: right;
  }
  .mainIcon {
    width: 22px;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .countries_menu_item {
    transition: 0.2s all;

    &:hover {
      opacity: 0.9;
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }
  .clock {
    color: black;
  }
  .countries_flag_main svg,
  .countries_flag svg {
    transform: translate(-15%, -50%);
  }
}
@media only screen and (max-width: 1024px) {
  .countries_text {
    font-size: 16px;
    font-family: Montserrat, Rubik;
    margin: 0px;
    // display: none;

    // :global{
    //   .dropdown-menu {
    //     top:initial;
    //     bottom:100%;
    //   }
    // }
  }
  .fromSideBar {
    margin: 0px;
  }
  .heb {
    font-family: 'Rubik', sans-serif;
  }
  .countries {
    padding: 0px !important;
  }
  .mainIcon {
    width: 22px;
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .heb {
    .mainIcon {
      margin-left: 5px !important;
    }
  }
  .countries_text {
    font-weight: 600;
  }
  .mainIcon2 {
    font-size: 18px;
    width: 24px;
    text-align: left;
  }
  .countries_menu {
    left: initial !important;
    right: 0px !important;
  }
  .menuAlignToBottom {
    top: initial !important;
    bottom: 100% !important;
  }
  .countries_flag_main {
    margin-left: 0px;
    margin-right: 6px;
    border-radius: 50%;
    overflow: hidden;

    svg {
      width: auto;
      height: 106%;
      transform: translate(-50%, -50%);
      position: relative;
      left: 50%;
      top: 50%;
    }
  }
  .heb {
    .countries_flag_main svg {
      transform: translate(-15%, -50%);
    }
    .countries {
      &:after {
        margin-right: 8px;
      }
    }
    .countries_menu {
      left: 0px !important;
      right: initial !important;
    }
    .clock {
      margin-left: 0px !important;
      margin-right: 15px !important;
    }
    .countries_flag_main {
      margin-left: 6px;
      margin-right: 0px;
    }
  }
}
