.wrapper {
  padding: 0px 25px;
  padding-top: 70px;
  padding-bottom: 30px;
  max-width: 780px;
  width: 100%;
}
.checkboxRow {
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px #e3e3ee solid;
  display: flex;
  justify-content: space-between;
}
.CheckboxText {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
}

.checkboxBootstrap {
  transform: scale(1.5);
}
.slider {
  cursor: pointer;
  border-radius: 30px;
  background: #f9f9f9;
  padding: 30px 65px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sliderText {
  width: 100%;
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
}
.price {
  min-width: 100px;
  font-family: Montserrat, Rubik;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4099999964237213px;
  margin-top: 19px;
}
.sliderInput {
  width: 100%;
  height: 4px;
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  margin-top: 23px;
}

.sliderInput::-webkit-slider-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-appearance: none;
  background: white;
  box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.3);
}
.forumTitle {
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
}
.forumTitleMini {
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16500000655651093px;
  text-align: left;
  color: rgba(0, 0, 0, 0.2);
  margin: 0px 4px;
}
.Input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}
.input_checkbox {
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #5d36bd;
  padding: 2px;
}
.input_checkbox_checked {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #5d36bd;
}
.input_wrapper {
  width: calc(100% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.input_wrapper_text {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
}
.inputElement {
  width: 100%;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  padding-bottom: 10px;
  border: none;
  margin-top: 10px;
  outline: none;
  border-bottom: 1px rgba(93, 54, 189, 0.3) solid;
}
.buttons {
  border-radius: 24px;
  width: 120px;
  display: flex;
  justify-content: center;
  background: rgb(233, 233, 233);
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 50%;
  font-size: 30px;
  box-sizing: border-box;
  // padding:8px;
  text-align: center;
}
.sub_title {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  margin-top: 60px;
}
.textarea {
  width: 100%;
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.4099999964237213px;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px rgb(219, 202, 254) solid;
  outline: none;
  margin-top: 19px;
}

.heb {
  direction: rtl;

  .checkboxBootstrap {
    position: relative;
    left: 9px;
  }

  .price {
    font-family: 'Rubik', sans-serif;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}
