@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

//laptop
.MainContainer {
  background: transparent;
}
@media #{$media-tablet} {
  .MainContainer {
    display: none;
  }
}
@media #{$media-laptop} {
  .title {
    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    /* identical to box height, or 58px */

    letter-spacing: -0.41px;

    color: #ffffff;

    margin-left: 50px;
    margin-right: 50px;
  }

  .heb {
    .title {
      font-family: 'Rubik';
      text-align: right;
    }
  }

  .creationsSection {
    margin-left: 50px;
    margin-right: 50px;
  }
  .contentContainer {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 16px;
  }
  .creationsContainer {
    height: 650px;
    margin-top: 25px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .childCreation {
    border-radius: 16px;
    margin: 0px;
    width: calc(50% - 30px);

    .verticalSpacer {
      height: 30px;
    }
    .horizSpacer {
      width: 60px;
      height: 100%;
    }

    .childOne {
      padding-right: 0px;
      height: 410px;
      padding-bottom: 0px;
    }

    .childTwo {
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      height: 210px;
    }

    .childThree {
      height: 210px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }

    .childFour {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      height: 410px;
    }
  }

  .blockerOverlayContainer {
    height: 700px;
    top: -20px;
    left: 0px;
    padding-bottom: 120px;
    position: absolute;
    width: calc(100vw - 100px);
    /*background: linear-gradient(
      180deg,
      rgba(14, 9, 30, 0) 0%,
      rgba(14, 9, 30, 0.96) 45.5%,
      #0e091e 100%
    );*/
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    bottom: 0px;
    z-index: 950;

    .title {
      /* Web/Subtitle 24px */

      margin-top: 20px;
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 110%;
      /* or 26px */

      text-align: center;

      color: #ffffff;
    }

    .imageLock {
      height: 36px;
      width: 36px;
    }

    .titleFreebie {
      /* Web/Subtitle 24px */

      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 110%;
      /* or 26px */

      text-align: center;

      color: #ffffff;
    }

    .logo {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 220px;
      height: 37.66px;
    }

    .titleFreebieBottom {
      display: none;
    }
  }

  .appStoreButtons {
    border-radius: 16px;
    height: 60px;
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    left: 0px;
    z-index: 1000;
    bottom: 250px;
    width: calc(100vw - 100px);

    .android {
      width: 180px;
      height: 52px;
      object-fit: contain;
    }

    .ios {
      width: 180px;
      height: 52px;
      object-fit: contain;
    }
  }
  .actionButton {
    background: #5d36bd;
    border-radius: 16px;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    position: absolute;
    left: calc(50% - 150px);
    z-index: 1000;
    bottom: 0px;
    width: 300px;

    .title {
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      margin-left: 0px;
      margin-right: 0px;
      /* identical to box height, or 16px */

      color: #ffffff;
    }

    .detail {
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  .realContentContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .heb {
    .benefits {
      // direction: rtl;
    }
  }

  .skeletonContainer {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  .benefits {
    display: none;
    height: 200px;
    margin-top: 20px;
    overflow-x: hidden;
    margin-bottom: 20px;

    .scrollContainer {
      top: 45px;
      left: 0px;
      height: 150px;
      overflow-x: scroll;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }

    .benefitsScrollableArea {
      left: 0px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .benefit {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      width: 150px !important;
      height: 150px !important;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 25px;

        background: linear-gradient(142.77deg, #cb8171 4.72%, #947fb7 109.77%);
      }

      .icon img {
        width: 24px;
        height: 24px;
      }

      .heb {
        .title {
          font-family: 'Rubik' !important;
          text-align: right;
        }
      }

      .title {
        /* Web/Small button 16px */
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 20px;
        width: 100%;
        // font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        color: #ffffff;
      }
    }
  }
}
//Mobile up to 767 px
@media only screen and (max-width: 767px) {
  .title {
    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .heb {
    .title {
      font-family: 'Rubik';
      text-align: right;
    }
  }

  .creationsSection {
    margin-left: 0px;
    margin-right: 0px;
  }
  .contentContainer {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 16px;
  }
  .creationsContainer {
    padding-left: 0px;
    padding-right: 0px;
    height: 335px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .childCreation {
    border-radius: 16px;
    width: calc(50% - 15px);

    .verticalSpacer {
      height: 15px;
    }
    .horizSpacer {
      width: 30px;
      height: 100%;
    }
    .childOne {
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      padding-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
      height: 100px;
    }

    .childTwo {
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      padding-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
      height: 210px;
    }

    .childThree {
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      padding-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
      height: 210px;
    }

    .childFour {
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      padding-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
      height: 100px;
    }
  }

  .blockerOverlayContainer {
    height: 340px;
    top: -5px;
    left: 0px;
    padding-bottom: 40px;
    position: absolute;
    width: calc(100vw);
    /*background: linear-gradient(
      180deg,
      rgba(14, 9, 30, 0) 0%,
      rgba(14, 9, 30, 0.96) 45.5%,
      #0e091e 100%
    );*/
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    bottom: 0px;
    z-index: 950;

    .title {
      width: 200px;
      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      margin-top: 16px;
      color: #ffffff;
    }

    .imageLock {
      height: 27.90423583984px;
      width: 25.649993896484px;
    }

    .titleFreebie {
      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
    }

    .logo {
      margin-top: 4px;
      margin-bottom: 4px;
      width: 118.42px;
      height: 20.27px;
    }

    .titleFreebieBottom {
      display: block;
    }
  }

  .appStoreButtons {
    border-radius: 16px;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    left: 20px;
    z-index: 1000;
    bottom: 20px;
    width: calc(100vw - 40px);

    .android {
      width: 136.82px;
      height: 39.52px;
      object-fit: contain;
    }

    .ios {
      width: 136.82px;
      height: 39.52px;
      object-fit: contain;
    }
  }
  .actionButton {
    background: #5d36bd;
    border-radius: 16px;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    position: absolute;
    left: 20px;
    z-index: 1000;
    bottom: 0px;
    width: calc(100vw - 40px);

    .title {
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      /* identical to box height, or 16px */

      color: #ffffff;
    }

    .detail {
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      color: #ffffff;
    }
  }

  .realContentContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .heb {
    .benefits {
      // direction: rtl;
    }
  }

  .skeletonContainer {
    width: 100%;
    padding-left: 17px;
    padding-right: 17px;
  }
  .benefits {
    display: flex;
    height: 200px;
    margin-top: 20px;
    overflow-x: hidden;
    margin-bottom: 20px;

    .scrollContainer {
      top: 45px;
      left: 0px;
      height: 150px;
      overflow-x: scroll;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }

    .benefitsScrollableArea {
      left: 0px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .benefit {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      width: 150px !important;
      height: 150px !important;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 25px;

        background: linear-gradient(142.77deg, #cb8171 4.72%, #947fb7 109.77%);
      }

      .icon img {
        width: 24px;
        height: 24px;
      }

      .heb {
        .title {
          font-family: 'Rubik' !important;
          text-align: right;
        }
      }

      .title {
        /* Web/Small button 16px */
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 20px;
        width: 100%;
        // font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        color: #ffffff;
      }
    }
  }
}
