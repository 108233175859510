.BookingUpload {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 20px;
}
.title {
  width: 100%;
  text-align: center;
  font-family: Montserrat, Rubik;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.41px;
  color: #111111;
  margin-top: 80px;
  margin-bottom: 45px;
}
.uploadBox {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 70px;
  background: #ffffff;
  border: 1px dashed #979797;
  box-sizing: border-box;
  border-radius: 10px;
  color: #979797;
  text-align: center;
  cursor: pointer;
}
.image {
  width: 138px;
  height: 138px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}
.loading {
  width: 100px;
  height: 138px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #5d36bd;
}
.text {
  width: 130px;
  font-family: Montserrat, Rubik;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: black;
  text-align: center;
  margin-top: 15px;
}
.hideVideo {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px #e3e3ee solid;
  margin-top: 40px;
}
.bottomText {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: left;
  color: black;
}
.bottomPriceText {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: #000000;
}
.checkboxBootstrap {
  transform: scale(1.7);

  label:after {
    height: calc(1rem - 4px) !important;
    top: calc(0.25rem + 2px) !important;
    cursor: pointer;
  }
}
.heb {
  .boxWrapper {
    // flex-direction: row-reverse;
  }
  .hideVideo {
    margin-right: 0px;
    // flex-direction: row-reverse;
  }
  .text {
    text-align: center;
  }
  .bottomText {
    text-align: right;
    margin-left: 0px;
    margin-right: 0px;
  }
  .checkboxBootstrap {
    // right: -12px;
    // left: initial;
  }
  .totalRow {
    // flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1024px) {
  .BookingUpload {
    margin-top: 0px;
  }
  .bottomPriceText {
    display: none;
  }
  .uploadBox {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 34px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .uploadBoxImg {
    line-height: 0px;
  }
  .boxWrapper {
    width: 100%;
  }
  .text {
    width: calc(100% - 64px);
    font-family: Assistant;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
  }
  .heb {
    .text {
      margin-left: 0px;
      text-align: right;
    }
    .checkboxBootstrap {
      margin-left: 30px;
      // right: initial;
      // left: initial;
    }
  }
  .bottomText {
    font-family: 'Assistant';
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    position: relative;
    top: 4px;
  }
  .heb .bottomText {
    // margin-right: 16px;
  }
  .hideVideo {
    // flex-direction: row-reverse;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
  }
  .heb .hideVideo {
    // flex-direction: row !important;
  }
  .checkboxBootstrap {
    // left: 16px;
  }
}
