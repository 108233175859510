.BookingWhoIsFor {
  width: 100%;
  opacity: 1;
}
.highlighted {
  font-weight: bold;
  margin-left: 3px;
  margin-right: 3px;

  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 135%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: -0.41px;

  color: #000000;
}

.boxes {
  width: 100%;
  height: 190px;
  position: relative;
}
.disable {
  filter: grayscale(100%);
  cursor: default;
  .img,
  .text {
    cursor: default !important;
  }
}
.img {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  cursor: pointer;
}
.marked {
  border: 2px #5d36bd solid;
}
.noRow {
  border-radius: 0px !important;
  display: flex !important;
}
.imgProfile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text {
  width: 100%;
  color: black;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  margin-top: 4px;
}
.borderBig,
.borderSmall {
  padding-left: 3px;
  color: black;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.41px;
  text-align: right;
  padding-top: 17px;
  margin-top: 5px;

  span {
    font-family: Montserrat, Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.41px;
    text-align: center;
  }
}
.borderBig {
  width: 61%;
  border-top: 2px #ffc69c solid;
}
.borderSmall {
  width: 28%;
  border-top: 2px #5d36bd solid;
}
.heb {
  direction: rtl;
  .boxes {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1024px) {
  .boxes {
    height: 130px;
  }
  .title {
    font-size: 16px;
  }
  .text {
    font-size: 14px;
  }

  .borderSmall {
    padding-top: 4px;

    .regular {
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .borderBig {
    padding-top: 4px;

    .regular {
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .img {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .boxes {
    margin-top: 30px;
    height: 100%;
    width: 100%;
  }

  .forMeContainer {
    width: 90px;
  }
  .forSomeoneElseContainer {
    width: 90px;
  }
  .forBusinessContainer {
    width: 90px;
  }

  .img {
    width: 80px;
    height: 80px;
  }
}
