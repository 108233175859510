$facebook: #3b5998;
$twitter: #00acee;
$google-plus: #dd4b39;
$pinterest: #c8232c;
$linkedin: #0e76a8;
$flickr: #ff0084;
$google: #dd4b39;
$youtube: #cd201f;

/* Fonts */
$font: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$secondary-font: 'Assistant', Roboto;

/* Brand Colors */
$white: #fff;
$purple: #5d36bd;
$purple-washed: rgba(93, 54, 189, 0.3);
$purple-grey: #e3e3ee;
$pink: #dbcafe;
$grey-washed: rgba(17, 17, 17, 0.5);
$grey-bright: #f9f9f9;
$red: #df1338;
$black: #000;
$primary-dark: #0e091e;
$success: #4cd964;
$error: #df1338;

//for material ui
$primary: #5d36bd;
$secondary: #ff6b00;
$secondary-light: #ffbe8e;

/* Animation Easing */
$fast: 0.25s;
$easing: cubic-bezier(0.39, 0.35, 0, 0.96);

/* Page layout */
$max-page-width: 1200px;

$nav-bar-mobile-height: 73px;
$nav-bar-tablet-height: 80px;
$nav-bar-laptop-height: 84px;
$nav-bar-desktop-height: 84px;

$size-mobile: 468px;
$size-tablet: 768px;
$size-laptop: 1400px;

/* Media Query */
$media-phone: 'only screen and (max-width : 767px)';
$media-tablet: 'only screen and (min-width: 768px) and (max-width: 979px)';
$media-laptop: 'only screen and (min-width: 980px) and (max-width: 1399px)';
$media-desktop: 'only screen and (min-width: 1400px)';
