.wrapper {
  height: 100vh;
  padding-top: 70px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  color: rgba(0, 0, 0, 0.4);
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
}
.button {
  background: rgb(93, 54, 189);
  border-radius: 18px;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  padding: 14px 28px;
  color: white;
}
.img {
  margin: 40px 0px;
}
.selected {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  flex-direction: column;
}
.selectedImage {
  width: 146px;
  height: 146px;
  object-fit: cover;
  border-radius: 18px;
  margin-top: 20px;
}
.selectedTitle {
  font-family: 'Assistant';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4099999964237213px;
  margin-top: 20px;
  text-align: center;
}
.selectedSubTitle {
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
  color: rgba(93, 54, 189, 1);
}
.selectedLink {
  font-family: Assistant;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 16px;
  letter-spacing: 0em;
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.infoImg {
  margin: 0px 10px;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 25px;
}
.trash {
  padding: 14px;
  border: 1px #dbcafe solid;
  border-radius: 18px;
  margin-left: 15px;
}
.saveButton {
  width: 100%;
  max-width: 250px;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  border-radius: 16px;
  background: #5d36bd;
  color: white;
}

.heb {
  direction: rtl;
  .trash {
    margin-right: 15px;
    margin-left: 0px;
  }
}
