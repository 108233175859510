@import "../../assets/scss/default/theme";
@import "../../assets/scss/default/mixins";

.roundLoader
{
  circle
  {
    @include animation(loading 1.8s cubic-bezier(0.74, 0.06, 0.59, 0.86) 0s infinite);
  }
}

@keyframes loading {
  0% {
    stroke-dasharray: 20;
    stroke-dashoffset: 35;
  }
  100% {
    stroke-dasharray: 40;
    stroke-dashoffset: 0;
  }
}