.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  color: rgba(0, 0, 0, 0.4);
}
.addLink {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin: 40px 0px 80px 0px;
  position: relative;
  left: -5px;
}
.addText {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  margin: 0px 15px;
  color: rgb(93, 54, 189);
}
.boxs {
  width: 100%;
  margin-top: 30px;
}
.box {
  width: 100%;
  padding: 15px;
  display: flex;
  box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.1);
  margin-bottom: 10px;
  border-radius: 23px;
}
.textBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.boxImg {
  margin-right: 15px;
}
.title {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
}
.subTitle {
  font-family: Montserrat, Rubik;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.4099999964237213px;
  color: rgb(93, 54, 189);
  margin: 5px 0px 9px 0px;
}
.date {
  color: rgba(17, 17, 17, 0.2);
  font-family: 'Assistant';
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
}
.heb {
  .boxImg {
    margin-left: 15px;
    margin-right: 0px;
  }
}
