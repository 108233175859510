.homepageSectionContainer {
  max-width: 1240px;
  margin: auto;

  @media only screen and (max-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .featuredChallenge {
    position: relative;
    padding: 24px;
    height: 200px;

    overflow: auto;
    background: rgba(93, 54, 189, 0.1);
    border-radius: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .gradientBackground {
      z-index: 1;
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      left: 0;
      background: linear-gradient(
        1.13deg,
        #231942 21.2%,
        rgba(35, 25, 66, 0) 99.08%
      );
      border-radius: 24px;
    }

    .title {
      /* H1 Headline 32px */
      z-index: 1;
      width: 80%;
      font-family: Assistant;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 110%;
      /* or 26px */

      /* Basic white */

      color: #ffffff;

      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  .websiteCard {
    padding: 24px;

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }

    height: 200px;
    background: rgba(93, 54, 189, 0.1);
    background-image: url('./images/blur_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 200px;
    backdrop-filter: blur(60px);
    border-radius: 24px;

    .title {
      /* H1 Headline 32px */

      width: 80%;
      font-family: Assistant;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 110%;
      /* or 26px */

      /* Basic white */

      color: #ffffff;

      @media only screen and (max-width: 767px) {
        font-size: 20px;
        // width: 50%;
      }
    }

    .subtitle {
      /* Button 2 16px */

      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 14px;
      /* identical to box height, or 87% */

      letter-spacing: -0.15px;

      /* Basic white */

      color: #ffffff;
    }
  }

  .topCreators {
    background-image: url('./images/top_creators_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 200px;
    height: 200px;
    padding: 24px;

    @media only screen and (max-width: 767px) {
      padding: 8px;
    }

    border-radius: 24px;
    .title {
      /* H4 Headline (tabs small) 20px */
      max-width: 100px;
      width: 75%;
      font-family: Assistant;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 120%;
      /* identical to box height, or 24px */

      text-align: center;
      letter-spacing: -0.41px;

      /* Blue gray dark */

      color: #231942;
    }
  }

  .campaigns {
    background: #ffbe8e;
    background-image: url('./images/top_creators_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 200px;
    height: 200px;
    padding: 24px;

    @media only screen and (max-width: 767px) {
      padding: 8px;
    }

    border-radius: 24px;
    .title {
      /* H4 Headline (tabs small) 20px */
      width: 90%;
      font-family: Assistant;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 120%;
      /* identical to box height, or 24px */

      text-align: center;
      letter-spacing: -0.41px;

      /* Blue gray dark */

      color: #231942;
    }
  }

  .newsAndCommunity {
    padding: 24px;

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }

    background: #ae9bde;
    border-radius: 24px;
    .title {
      /* H4 Headline (tabs small) 20px */
      max-width: 130px;
      width: 85%;
      font-family: Assistant;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 120%;
      /* identical to box height, or 24px */

      letter-spacing: -0.41px;

      /* Blue gray dark */

      color: #231942;

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  .appStoreButtons {
    padding: 24px;

    @media only screen and (max-width: 767px) {
      padding: 0px;
      padding-top: 24px;
    }

    div {
      width: 64px;
      height: 64px;

      border-radius: 50%;

      /* Blue gray dark */
      background: #231942;
    }
  }
}
