@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.video_ripple {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  transform: scale(2);
  top: 0;
}
.video_ripple_finished {
  clip-path: circle(300% at 50% 93%);
}

//Main container of all content
.top_container {
  display: block;
  transition: 0.5s;
}

//Video player
.video_background {
  position: absolute;
  top: 0;
  opacity: 0.2;
  width: 100%;
  transition: 0.5s;
  height: calc(100vh - (100vh - 100%)) !important;
  background: transparent;

  video {
    width: 100%;
    max-width: 100%;
    filter: blur(4px);
    height: 100vh;
    transition: 1s;
  }
}

.action_buttons_container {
  padding-left: 00px;
  padding-right: 00px;

  .buttonWrapper {
    width: 100%;
    max-width: 350px;
    margin-top: 20px;
  }
}

.charityIcon {
  margin-left: 8px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

.video_play_mode {
  opacity: 1;
  z-index: 9999;
  background: #0e091e;

  video {
    filter: blur(0);
  }

  .top_container_hidden {
    transition: 0.5s;
    opacity: 0;
  }
}

.video_play_btn {
  text-align: center;
  position: fixed;
  z-index: 99999;

  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  width: 100%;
  padding-bottom: 30px;
  bottom: 0px;

  button {
    height: 100%;
    font-size: 24px;
    border: 0;
    padding: 3px 10px 9px 14px;
    color: white;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
  }
}

.pause_btn {
  button {
    padding: 3px 12px 9px 12px;
  }
}

.Mobile_header_top_main {
  display: flex;
  padding-top: 0px;
}

.skeletonContainer {
  padding-top: 0px;
}

.Main {
  width: 100%;
  position: relative;
  transition: 0.4s opacity;
  z-index: 2;
}

.Mobile_header_user_image {
  img {
    margin-top: 0px;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    object-fit: cover;
  }
}
.Mobile_header_user_handle {
  margin-top: 20px;
  font-family: Assistant;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  text-align: center;

  color: rgba(200, 202, 207, 1);
}

.Mobile_header_full_name {
  font-family: 'kre8font';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 29px */

  letter-spacing: -0.41px;
}

.Mobile_header_user_title {
  font-family: 'kre8font';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  color: #ae9bde;
}

.Main_links {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Main_links_link {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 18px;
  margin-right: 8px;
  margin-top: 40px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: 0.2s all;
  color: white;

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.02);
  }
}
.Main_links {
  flex-wrap: wrap;
  margin-top: 20px;
}
.Main_links_link {
  margin-top: 10px;
}

.play_loader {
  left: 50%;
  margin-left: -31px;
  position: absolute;
  width: 62px;
  height: 62px;
  bottom: 23px;
}

.play_loader svg {
  width: 62px;
  height: 62px;
  fill: none;
  stroke: #997fb0;
  stroke-width: 3px;
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: loading 3000ms ease-in-out alternate;
}

//ALL
@keyframes loading {
  100% {
    stroke: #cb8171;
    stroke-dasharray: 10;
    transform: rotate(200deg);
  }
}

.video_ripple {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

.video_ripple_finished {
  clip-path: circle(130% at 50% 93%);
}

.video_rip {
  z-index: 99999;
}
