@import '../../assets/scss/default/theme';

.wrapperSearch {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.wrapper {
  height: $nav-bar-mobile-height;
  background: white;
  z-index: 1000;
  width: 100%;
  border-bottom: 1px rgb(211, 211, 211) solid;
  padding: 25px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
  color: black;
}
.save {
  color: rgb(93, 54, 189);
  font-family: Assistant;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}
.search {
  border: none;
  outline: none;
}
.heb {
  // .wrapper {
  //   flex-direction: row-reverse;
  // }

  .backCarrot {
    transform: scaleX(-1);
  }
  .title {
    font-family: 'Rubik', sans-serif;
  }
}
