@import '../../../assets/scss/default/mixins';
@import '../../../assets/scss/default/theme';

.halfCard {
  width: 100%;
  position: absolute;
  height: 180px;
  background: #64568c;
  border-radius: 40px 40px 0px 0px;
}
.cardContainer {
  position: relative;
  background: rgba(68, 57, 101, 0.6);
  border-radius: 40px;
  height: 100%;

  max-width: 395px;

  .cardChildren {
    z-index: 10;
    max-width: 275px;
  }
}
.headers {
  text-align: center;
  .specialOffer {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    text-align: center;
    letter-spacing: -0.165px;

    /* Orange #FFBE8E */

    color: #ffbe8e;
  }

  .title {
    /* Web/Big button 18px */

    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: -0.41px;

    /* White */

    color: #ffffff;
  }
}

.profilePicture {
  img {
    object-fit: cover;
    border-radius: 25%;
    width: 148px;
    height: 148px;
    min-width: 148px;
    min-height: 148px;
  }
}

.titles {
  .handle {
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    text-align: center;

    color: #ffffff;
  }
  .name {
    font-family: kre8font;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */

    letter-spacing: -0.41px;

    /* White */

    color: #ffffff;
  }

  .title {
    font-family: kre8font;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    /* Purple #5D36BD 50% */

    color: #ae9bde;
  }
}

.description {
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  text-align: center;
  letter-spacing: -0.165px;

  /* White */

  color: #ffffff;
}
.ctaButton {
  height: 62px;

  border-radius: 31px !important;
  align-items: center;
  display: flex;

  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.165px;
  color: white;

  border: 2px solid #dbcafe !important;
  color: #dbcafe !important;

  .amount {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: right;
    letter-spacing: -0.41px;

    /* Purple #5D36BD 20% */

    color: #dbcafe;
  }
}

//MOBILE
@media #{$media-phone} {
  .halfCard {
    position: absolute;
    height: 134px;
  }
}
