@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
  text-align: unset !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// change the theme
$primary: #5d36bd !default;

$border-radius: 0.75rem !default;

$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

@font-face {
  font-family: 'kre8font';
  font-weight: 500;
  src: local('kre8font'),
    url(./assets/fonts/kre8Medium-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'kre8font';
  font-weight: 700;
  src: local('kre8font'),
    url(./assets/fonts/kre8Bold-Regular.otf) format('opentype');
}

$gray-400: #e3e3ee;

// Links
$link-hover-decoration: none;

.input-group .form-control {
  border-top: none;
  border-left: none;
  border-right: none;

  &:focus {
    border-color: #5d36bd;
    caret-color: #5d36bd;

    ~ .input-group-append {
      border-color: #5d36bd;
    }
  }
}

$list-group-border-width: 0;

$input-padding-x: 0;
$input-font-family: Assistant;
$input-font-size: 18px;
$input-font-weight: 600;
$input-border-radius: 0 !important;
//$input-line-height: 120% !default;
$input-color: black;

$input-focus-box-shadow: none;

$input-placeholder-color: #c8cacf;

.input-group-append {
  border-bottom: 1px solid #e3e3ee;
}

// Dropdowns
$dropdown-padding-y: 0.75rem !default;
$dropdown-border-radius: 16px;

.modal-content {
  border: none !important;
}

.auth .modal-content {
  background: none !important;
}

.vh100 {
  height: 100vh !important;
}

.image-cover {
  object-fit: cover !important;
}

@import '~bootstrap/scss/bootstrap';
