@import '../../assets/scss/default/theme';

.contentContainer {
  height: 100vh;
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: $size-tablet;
  width: 100%;
  background: #0e091e;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navbar {
    height: 78px;
    .closeIcon {
      width: 24px;
      height: 24px;
    }
  }

  .CreatorHeader {
    margin-bottom: 30px;
    .avatar {
      object-fit: cover;
      width: 106px;
      height: 106px;
      background: linear-gradient(
        180deg,
        rgba(14, 9, 30, 0) 0%,
        rgba(14, 9, 30, 0.4) 100%
      );
      border-radius: 30px;
    }
    .title {
      /* Web/Subtitle 24px */

      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 110%;
      /* or 26px */

      color: #ffffff;
    }

    .detail {
      /* Web/Small heading 18px */
      margin-top: 3px;
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: -0.165px;

      color: #dbcafe;
    }
  }

  .InfoSection {
    margin-bottom: 57px;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid white;
    border-radius: 16px;

    padding-bottom: 20px;
    background: rgba(255, 255, 255, 0.2);

    .title {
      /* Web/Small heading 18px */

      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: -0.165px;

      color: #ffffff;
    }

    .detail {
      /* Web/Paragraph 18 px */

      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.165px;

      color: #ffffff;
    }
  }

  .BenefitsSection {
    margin-bottom: 50px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .Benefit {
      height: 44px;

      .heb {
        div {
          font-family: Assistant;
        }
      }

      div {
        /* Web/Small button 16px */

        font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        color: #ffffff;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .SubscribeButton {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px !important;
    height: 62px;
    background: #5d36bd;
    border-radius: 16px;
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.165px;

    color: #ffffff;
  }
  .TermsButton {
    /* Web/Big button 18px */
    text-align: center;

    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    /* or 22px */

    letter-spacing: -0.41px;

    color: rgba(255, 255, 255, 0.5);
  }
}
