@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.Container {
  background: $primary-dark;
  z-index: 10000;
}

.heb {
  direction: rtl;
}
