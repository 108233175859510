@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

//DESKTOP + MASTER
.navbar {
  height: $nav-bar-desktop-height;
  padding-right: 60px;
  padding-left: 60px;

  color: #000;

  &.transparent {
    border: none;
    background: none !important;
  }
}

.withBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.trailingSideInputs {
  display: flex;
}
.wrapper {
  max-width: 1200px;
  justify-content: space-between !important;

  .trailingSideElementsMobile {
    display: none;
  }

  .nonAuthedUserInfo {
    height: 50px;
    .logInButton {
      padding-left: 16px;
      padding-right: 16px;
      /* Web/Big button 18px */
      height: 100%;
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 120%;
      /* identical to box height, or 22px */

      letter-spacing: -0.41px;

      color: #5d36bd;
    }
    .signUpButton {
      padding-left: 16px;
      padding-right: 16px;
      height: 100%;
      /* Web/Big button 18px */
      background: #5d36bd;
      border-radius: 100px;
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 120%;
      /* identical to box height, or 22px */

      letter-spacing: -0.41px;

      color: #ffffff;
    }

    .authButtonMobile {
      display: none !important;
    }
  }
}
.centerLogo {
  position: absolute;
}
.logo {
  background: rgba(255, 255, 255, 0.92) url('./logo.svg') center center
    no-repeat;
  font-size: 0 !important;
  min-width: 120px;
  min-height: 25px;
  z-index: 2;
  position: relative;

  &:hover {
    background-image: url('./logo.svg');
  }
}
.shopNowButton {
  height: 40px;
  margin-left: 2px;
  margin-right: 2px;
  background: #5d36bd !important;
  background-color: #5d36bd !important;
  border-radius: 24px !important;

  font-family: Assistant !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #ffffff !important;
}
.buttonSmall {
  margin-left: 18px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  cursor: pointer;
  &:hover {
    background: #5d36bd;
    color: 'white';
  }
}
.create {
  font-family: Assistant, serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 100% !important;
  padding: 15px 30px !important;
}

.toggler {
  border: none !important;
  span {
    background-image: url('./icon_menu.svg') !important;
  }
}

.searchToggler {
  border: none !important;
  span {
    background-image: url('./icon_search_toggler.svg') !important;
  }
}
.profile {
  align-items: center;
}

.profile_img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  background: rgb(50, 50, 50);
}
.messageIcon {
  margin-right: 10px;
}
.profile_text {
  color: black;
  font-weight: 500;
  letter-spacing: -0.41px;

  font-family: 'kre8font', Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  letter-spacing: -0.41px;
}
.rtl {
  .profile_text {
    direction: rtl;
  }
  .profile_img {
    margin-right: 0px;
    margin-left: 15px;
  }
}
.white {
  border-bottom: 1px rgba(150, 150, 150, 0.2) solid;

  .loginSignUp {
    color: black;
  }
}
.dark {
  .profile_text {
    color: white;
  }
  // background:rgba(38, 34, 52, .92);
  background: #0e091e;
  border-bottom: 1px rgba(255, 255, 255, 0.2) solid;

  .line {
    background: white;
  }

  .profile {
    display: flex;
  }
  .toggler span {
    background-image: url('./menuWhite.svg') !important;
  }

  a {
    background: none;
    color: white;
  }

  button {
    color: white !important;

    &:hover {
      color: white;
    }
  }

  input {
    background: none;
  }

  .logo {
    background: url('./logoWhite.svg') center center no-repeat;
  }
  // button:after {
  //   background: url(./arrow_white_down.svg) center center no-repeat !important;
  // }
  :global {
    .container {
      max-width: 1024px !important ;
      padding: 0px;
    }
  }

  .logInButton {
    color: white !important;
  }

  .signUpButton {
    background: white !important;
    color: #5d36bd !important;
  }
}
.hamburger {
  width: 20px;
  height: 18px;
  transition: 0.2s all;
  z-index: 111111;
  position: absolute;
  bottom: calc(50% - 9px);
  left: calc(50% - 10px);
  // top: 8px;
  // right: 0px;
}
.hamburgerOpen {
  .line1 {
    transform: rotate(-45deg);
    top: calc(50% - 1.5px);
  }
  .line2 {
    opacity: 0;
  }
  .line3 {
    transform: rotate(45deg);
    bottom: calc(50% - 1.5px);
  }
}
.hamburgerMainColor .line {
  background: rgb(93, 54, 189);
}
.line {
  width: 100%;
  height: 3px;
  background: black;
  border-radius: 20px;
  transition: 0.2s all;
  position: absolute;
}
.line1 {
  top: 0%;
}
.line2 {
  transform: translateY(-50%);
  top: 50%;
}
.line3 {
  bottom: 0%;
}
.heb {
  .profile_text {
    font-family: 'Assistant', sans-serif;
  }
  direction: ltr;
  .centerLogo {
    margin-right: -620px;
    margin-left: 0px;
    left: initial;
    right: 50%;
  }
  .hamburger {
    left: 0px;
    right: initial;
  }
}

.ProfileIcon {
  display: none;
  fill: white;
  display: none;
}
.homeProfile {
  display: block;
}
.loginSignUp {
  color: white;
  font-size: 18px;
  display: block;
  cursor: pointer;
  font-family: Montserrat, Rubik;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* identical to box height, or 22px */

  letter-spacing: -0.41px;
}

//TABLET
@media #{$media-tablet} {
  .navbar {
    padding-left: 30px !important;
    padding-right: 30px !important;
    height: $nav-bar-tablet-height;
  }
  .userInfo {
    display: none !important;
  }

  .logo {
    // left: 20px;
  }
  // .hamburger {
  //   margin-left: 10px;
  // }
  .heb {
    .logo {
      // right: 20px;
    }
    .profile_img {
      margin-left: 25px;
    }
  }
  .loginSignUp {
    display: none;
  }
  .countries {
    // display: none;
  }
  .profile_img {
    display: none;
  }
  .centerLogo {
    margin: 0px !important;
    left: initial;
    right: initial !important;
    position: relative;
    float: right;
  }
  .centerLogo {
    margin: 0px !important;
    left: initial;
    right: initial !important;
    position: relative;
    float: right;
  }
  .wrapper {
    width: auto;
    transform: translateX(0px);
    left: initial;
  }
  .ProfileIcon {
    display: none;
    margin-right: 10px;
  }
  .heb {
    .hamburg {
      margin-left: 5px;
    }
    .wrapper {
      right: initial;
      left: initial;
    }
    .ProfileIcon {
      margin-right: 0px;
      margin-left: 45px;
    }
  }
  .dark,
  .white {
    :global {
      .container {
        width: 50%;
        max-width: 984px !important ;
        margin: 0px;
        padding: 0px;
      }
    }
    .toggler {
      padding: 0px;
      margin-left: 10px;
    }
  }
  .profile_text {
    display: none;
  }

  .rtl {
    left: 0px;
  }

  .profile {
    margin-right: 5px;
    margin-left: 5px;
  }
  .profile_img {
    margin-right: 5px;
    margin-left: 35px !important;
  }
}
.rtl {
  direction: rtl !important;

  .messageIcon {
    margin-right: 0px;
    margin-left: 0px;
  }
}

//MOBILE
@media #{$media-phone} {
  .hideInMobile {
    display: none;
  }

  .navbar {
    height: $nav-bar-mobile-height;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .messageIcon {
    margin-left: 0px;
  }
  .trailingSideInputs {
    display: none;
  }

  .profile_text {
    display: none;
  }

  .wrapper {
    // padding-right: 20px;
    // padding-left: 20px;
  }

  .trailingSideElementsMobile {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  .authButtonMobile {
    width: 30px;
    height: 30px;
  }

  .wrapper {
    .nonAuthedUserInfo {
      .logInButton {
        display: none !important;
      }
      .signUpButton {
        display: none !important;
      }
    }
  }
}
