@import '../../../assets/scss/default/mixins';
@import '../../../assets/scss/default/theme';
.root {
  background: #221c34;
}
.heroBackgroundImage {
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100%;
  background: #221c34;

  img {
    width: 100%;
    height: 100%;
    transform: scale(1.5, 1.5);
    opacity: 0.1;
  }
}
.container {
  height: 100%;
  position: absolute;
  background: #221c34;
}
.titles {
  text-align: center;
  .welcome {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */

    text-align: center;
    letter-spacing: -0.31px;

    /* Purple #5D36BD 50% */

    color: #ae9bde;
  }

  .logo {
    img {
      height: 43px;
      width: auto;
    }
  }

  .subtitle {
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    letter-spacing: -0.165px;

    color: #fff;
  }
}

.bottomSection {
  max-width: 375px;
  .info {
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .cta {
    height: 62px;
    border-radius: 31px;
  }
}

//MOBILE
@media #{$media-phone} {
  .titles {
    .welcome {
    }

    .logo {
      img {
        height: 30px;
        width: auto;
      }
    }
  }
}
