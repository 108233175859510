.bookingLoading {
  height: 90vh;
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  max-width: 1056px;
  max-height: 839px;
  margin-left: auto;
  margin-right: auto;
}
.loading {
  .title,
  .subTitle,
  .downText {
    opacity: 0;
  }
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
  color: rgb(93, 54, 189);
  transition: 0.2s;
}
.subTitle {
  max-width: 495px;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  color: black;
  margin-top: 10px;
  transition: 0.2s;
}
.downText {
  display: none;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.4099999964237213px;
  text-align: left;
  color: black;
  // display: flex;
  transition: 0.2s;
}
.userProfile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 60px;
  left: 60px;
}
.creatorProfile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: 125px;
  right: 31px;
}
.imgWrapper {
  width: 300px;
  margin: 20px 0px;
  position: relative;
}
.img {
  width: 300px;
}
.button {
  background: rgb(93, 54, 189);
  border-radius: 20px;
  padding: 20px 80px;
  color: white;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    transform: scale(1.05);
  }
}
.checkboxBootstrap {
  display: flex !important;
  justify-content: flex-end;
  transform: scale(1.7);
  margin-top: 25px;
  margin-left: 10px;
  position: relative;
  right: 15px;
  top: -24px;

  label:after {
    // height: calc(1rem - 5px) !important;
    // top: calc(0.25rem + 3px) !important;
  }
}
.buttonLoading {
  background: white;
  // color:rgb(93,54,189);
  color: rgb(93, 54, 189);
  padding: 20px;
  // background:rgb(93,54,189);
  cursor: default;
}
.loading_text {
  direction: ltr;
  color: black;
  font-family: 'Assistant';
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.4099999964237213px;
}
.heb {
  direction: rtl;

  .loading_text {
    direction: rtl;
  }

  .title {
    font-family: 'Rubik', sans-serif;
  }
}

//mobile
@media only screen and (max-width: 767px) {
  .bookingLoading {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}
