.wrapper{
    width: 250px;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.2);
    padding:15px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right:15px;
}
.text{
    width: 220px;
    margin-top:10px;
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.2800000011920929px;
    text-align: center;
}
.heb{
    margin-left:15px;
    margin-right:0px;
}