.marginTop {
  margin: 30px 0px;
}
.next {
  height: 62px;
  min-width: 200px;
  background: rgb(93, 54, 189);
  border-radius: 16px;
  text-align: center;
  line-height: 62px;
  font-family: Assistant;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.165px;
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.2s all;
  color: white;

  &:hover {
    transform: scale(1.05);
  }
}

.back {
  padding: 0px 25px;
  height: 62px;
  line-height: 58px;
  border: 2px #dbcafe solid;
  border-radius: 18px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background: #dbcafe;
  }
}
.error {
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  color: red;
}
.heb {
  .back {
    margin-right: 0px;
    margin-left: 20px;
    transform: rotate(180deg);
  }
}
@media only screen and (max-width: 1024px) {
  .back {
    display: none;
  }
  .marginTop {
    margin: 30px 0px 0px 0px;
  }
  .next {
    min-width: 200px;
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .extraStyle {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    text-align: center;

    color: #ffffff;
  }
  .marginTop {
    margin-top: 0px;
  }
  .next {
    height: 50px;
    margin-top: 0px;
  }
  .back {
    height: 50px;
    margin-top: 0px;
  }
}

//mobile
@media only screen and (max-width: 767px) {
  .extraStyle {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    text-align: center;

    color: #ffffff;
  }
}
