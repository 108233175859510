.ComponentToRender {
  padding-top: 83px;
}
.wrapper {
  margin: 20px;
}
.boxes {
  min-height: 120px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  width: 100%;
  overflow: scroll;
}
.spacer {
  height: 100%;
  width: 20px;
  opacity: 0;
}
.footer {
  margin-top: -50px;
}
.heb {
  direction: rtl;
}

.MasterContainer {
  width: 100%;
}
.ContentContainer {
  width: 100%;
  background: #f9f9f9;
  padding: 20px;
}

.ContentContainerChild {
  max-width: 780px;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background: white;
}
@media only screen and (max-width: 768px) {
  .ContentContainer {
    width: 100%;
    padding: 0px;
  }

  .ContentContainerChild {
    padding: 0px;
  }
}
