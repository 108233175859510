@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.MasterContainer {
  width: 100%;
}
.ContentContainer {
  max-width: $size-tablet;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  .Section {
    .Header {
      padding-left: 25px;
      padding-right: 25px;
      margin-bottom: 25px;
      position: relative;
      height: 50px;
      justify-content: center;

      img {
        margin-right: 10px;
      }

      .title {
        font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        // line-height: 16px;
        /* identical to box height */

        letter-spacing: -0.165px;

        color: #5d36bd;
      }

      .divider {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        background: #e3e3ee;
      }
    }

    .Row {
      padding-left: 25px;
      padding-right: 25px;
      height: 50px;
      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      letter-spacing: -0.165px;

      color: #000000;

      .light {
        color: rgba(17, 17, 17, 0.4);
      }

      .alert {
        color: #e51f1f;
      }

      .content {
        width: 100%;
      }
    }
  }
}
