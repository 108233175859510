.path {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #cecece;
}
.bold {
  text-transform: uppercase;
  color: rgb(151, 151, 151);
  font-family: 'Assistant';
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
}
