.Creation {
  width: 289px;
  height: 410px;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(39, 37, 50, 1);
  transition: 0.2s all;
  border-radius: 16px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  &:hover {
    .play {
      background: rgba(255, 255, 255, 0.4);
      transform: scale(1.05) translate(-50%, -50%);
    }
  }
}
.CreationDynamic {
  height: 100%;
  width: 100%;
}
.text {
  height: 90px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  position: relative;
  display: flex;
  // align-items: center;

  margin-top: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  -webkit-line-clamp: 3; /* after 3 line show ... */
  -webkit-box-orient: vertical;
}
.CreationVideo {
  cursor: pointer;

  .Creation_profile_textTitle {
    display: block;
  }

  .play {
    display: block;
  }

  .Creation_footer_title {
    display: none;
  }
}
.play {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  transform: translate(-50%, -50%);
  transition: 0.2s all;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;

  img {
    margin-left: 1px;
    margin-top: -1px;
    transform: translate(-50%, -50%);
    position: relative;
    left: 50%;
    top: 50%;
  }
}
.image {
  width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  object-fit: cover;
  left: 50%;
}
.imageBlur {
  filter: blur(15px);
  object-fit: cover;
}
.Creation_profile_textTitle {
  // display: none;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  z-index: 2;
}
.videoIcon {
  width: 22px;
  margin-right: 4px;
}
.CreationText {
  height: 207px;

  .Creation_footer_title {
    display: none;
  }

  .Creation_profile_textTitle {
    display: block;
  }
  .text {
    display: flex;
  }

  .shadowTop,
  .shadowBottom {
    display: none;
  }
}
.Creation_profile_time {
  height: 12px;
  width: 80px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: #ffc69c;
  width: 70%;
  position: relative;
  z-index: 2;
}
.Creation_footer {
  width: calc(100% - 40px);
  position: absolute;
  bottom: 20px;
}
.Creation_footer_title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* after 3 line show ... */
  -webkit-box-orient: vertical;
}
.Creation_footer_likes {
  display: flex;
  margin-top: 20px;
}
.Creation_footer_likes_box {
  margin-right: 15px;

  img {
    margin-right: 2px;
  }
}
.viewsText {
  margin-right: 5px;
}
.Creation_footer_likes_box_v {
  margin-top: 3px;
}
.margins {
  position: relative;
  top: 2px;
  left: 4px;
}
.marginsNoLeft {
  left: 0px;
}
.lockIcon {
  font-size: 12px;
  font-weight: 600;
  display: block;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 3;
}

.imageLock {
  width: 15px;
  position: relative;
  top: -3px;
  left: -3px;
}
.creationsLock {
  cursor: pointer;
  .text {
    filter: blur(5px);
    align-items: center;
  }
  .image {
    filter: blur(25px);
    align-items: center;
  }
}
.fromBuy {
  margin-right: 0px;
}

.heb.fromBuy {
  float: right;
}

.whiteTheme {
  background: rgb(249, 249, 249);

  .text {
    font-family: Assistant;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.16500000655651093px;
  }
}
.heb {
  .text,
  .Creation_profile_textTitle {
    text-align: right;
    direction: rtl;
  }
  .Creation_footer_title {
    text-align: right;
  }
  .Creation_footer_likes {
    justify-content: flex-end;
  }
  .Creation_footer_likes_box {
    margin-right: 0px;
    margin-left: 15px;
    display: flex;
    flex-direction: row-reverse;
  }
  .Creation_profile_time {
    text-align: right;
    float: right;
  }
  .margins {
    left: initial;
    right: 4px;
  }
  .imageLock {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .allPageInMobil {
    width: 100%;
    margin-right: 0px;
  }
  .CreationDynamic {
    height: 100%;
    width: 100%;
  }
}
