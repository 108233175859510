.BookingOccasion {
  width: 100%;
}
.boxes {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
}
.box {
  width: 33%;
  margin-top: 30px;
}
.boxSelected {
  .imgBox {
    border: 2px #5d36bd solid;

    &:hover {
      border: 2px #5d36bd solid;
    }
  }
}
.imgBox {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(118, 138, 149, 0.1);
  border-radius: 18px;
  border: 2px white solid;
  transition: 0.2s all;

  &:hover {
    border: 1px #5d36bd solid;
  }
}
.text {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.heb {
  .boxes {
    direction: rtl;
  }
}
//tablet
@media only screen and (max-width: 1024px) {
  .imgBox {
    width: 60px;
    height: 60px;
  }
  .text {
    font-size: 14px;
  }
  .box:nth-child(1),
  .box:nth-child(2),
  .box:nth-child(3) {
    margin-top: 0px;
  }
  .imgBox:hover {
    border: 2px white solid;
  }
}
//phone
@media only screen and (max-width: 767px) {
  .boxes {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .BookingOccasion {
    height: auto;
  }
  .text {
    margin-top: 4px;
  }
  .box {
    margin-top: 20px;
  }
}
