@import '../../../assets/scss/default/theme';

.navbar {
  position: absolute;
  margin-top: 10px;
  width: 100%;
  height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

.booking {
  height: 90vh;
  position: relative;
  z-index: 10;
  width: 100vw;
  max-width: 1000px;
}
.heightAllPage {
  height: calc(100vh - 134px);

  .termsRow {
    bottom: 40px;
  }
}
.rightArea {
  width: 50%;
  // max-height: calc(90vh - 0px);
  max-height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-top: 80px;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;
}
.rightAreaFlex {
  // height:calc(100% - 80px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}
.leftArea {
  width: 50%;
  height: 100%;
  overflow: auto;
  padding: 40px 60px;
  padding-top: 80px;
  background: #f9f9f9;
  position: relative;
}
.title {
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  color: black;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
}
.termsRow {
  margin-top: 20px;
  z-index: 2;
  position: fixed;
  left: 33px;
  bottom: 40px;
}
.termsRowHowItWorks {
  .info {
    width: 20px !important;
  }
}
.termsRowFromLeft {
  left: 40px;
}
.infoHolder {
  width: 36px;
  height: 36px;
  box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.2);
  padding: 4px;
  margin-right: 12px;
  padding: 4px;
  border-radius: 50%;
  transition: 0.2s all;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;

  .info {
    width: 4px;
  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.4);
  }
}
.Terms {
  cursor: pointer;
  padding: 6px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.165px;
  color: #5d36bd;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.2);
  border-radius: 23px;
  transition: 0.2s all;
  float: left;

  &:hover {
    box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.4);
  }
}
.termsMobile {
  display: none;
}
.heb {
  display: rtl;
  flex-direction: row-reverse;
  .title,
  .termsMobile {
    direction: rtl;
  }
  .infoHolder,
  .Terms {
    margin-right: 0px;
    margin-left: 12px;
    float: right;
  }
  .termsRow {
    left: initial;
    right: 33px;
  }
  .termsRowHowItWorks {
    right: 80px;
  }
  .info1 {
    margin-right: 4px;
  }
}
@media only screen and (max-width: 1024px) {
  .booking {
    height: calc(100% + 0px);
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .termsMobile {
    height: 17px;
    width: 100%;
    display: block;
    color: black;
    font-family: 'Assistant';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 4px;
    }
  }
  .leftArea {
    padding-top: 0px;
    height: 60%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin: 0px;
    background: white;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .leftAreaBig {
    height: 100%;
  }

  .rightArea {
    height: 45%;
    margin: 0px;
    background: white;
    width: 100%;
    max-width: 600px;
    border-radius: 48px;
    padding: 20px;
  }

  .Terms,
  .infoHolder {
    display: none;
  }
  .rightAreaFlex {
    max-height: 340px;
    width: 100%;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-radius: 48px;
    margin-top: 0px;
    background: #f9f9f9;
  }
  .title {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

//mobile
@media #{$media-phone} {
  .navbar {
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    margin-bottom: 0px;
  }
  .booking {
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh;
    width: 100%;
    padding-bottom: 30px;
  }
  .rightArea {
    width: 100%;
    max-height: 300px;
    height: 300px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    .lineStepsContainer {
      position: absolute;
      top: 0;
      margin-top: -50px;
    }
  }
  .rightAreaFlex {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    text-align: center;

    color: #000000;
  }

  .leftAreaBig {
    height: 100% !important;
  }

  .leftArea {
    height: 55%;
  }

  .heb {
    .title {
      font-family: 'Rubik';
    }
  }
}
