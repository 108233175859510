.fullname {
  font-family: 'kre8font';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.wrapper {
  padding: 20px;
  min-width: 245px;
}

.backgroundClick {
  height: 100%;
  width: 100%;
  background: red;
  position: fixed;
  top: 0px;
  left: 0px;
}
.sidebar {
  position: fixed;
  top: -20px;
  right: -20px;
  width: 0px;
  transition: 0.2s all;
  padding: 0px;
  height: calc(100vh + 20px);
  overflow: hidden;
  background: #ffffff;
  box-shadow: -10px 0 16px rgba(118, 138, 149, 0.1);
  z-index: 9999;

  &.rtl {
    right: initial;
    left: -20px;
    box-shadow: 10px 0 16px rgba(118, 138, 149, 0.1);

    .close {
      right: auto;
      left: 0;
    }

    .item {
      background-position: right 15px center;
      text-align: right;
    }

    .links {
      text-align: right;
      right: 0;
    }
  }
  .close {
    width: 20px;
    height: 20px;
    background: url('../NewNavbar/icon_close.svg') center center no-repeat;
    right: 0;
    top: 0;
    position: absolute;
  }

  .links {
    position: absolute;
    bottom: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    width: 100%;
  }

  .item {
    font-family: Assistant, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #c8cacf;
    background-position: 15px center;
    background-repeat: no-repeat;

    &.active {
      color: #5d36bd;
    }

    &.home {
      background-image: url('../NewNavbar/icon_home.svg');

      &.active {
        background-image: url('../NewNavbar/icon_home_hover.svg');
      }
    }

    &.inbox {
      background-image: url('../NewNavbar/icon_inbox.svg');
    }

    &.profile {
      background-image: url('../NewNavbar/icon_profile.svg');
    }

    &.search {
      background-image: url('../NewNavbar/icon_search1.svg');
    }

    &.logout {
      background-image: url('../NewNavbar/icon_exit.svg');
    }
  }
}
.isVisible {
  width: 275px;
}
.row {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  color: black;

  &:first-child {
    margin-top: 36px;
  }
  img {
    margin-right: 15px;
  }
}
.rowActive {
  font-weight: bold;
  color: white;
}
.marginTop {
  margin-top: 40px;
}
.rtl {
  .row {
    img {
      margin-right: 0px;
      margin-left: 15px;
    }
  }
}
.dark,
.white {
  background: #3e3a4b;
  color: white;

  .row {
    color: white;
  }
  .links a {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
  }
  :global {
    .list-group-item {
      background-color: transparent !important;
      color: #979797;
    }

    .list-group-item.disabled {
      background: none;
      padding-left: 0px;
      padding-top: 0px;
    }
    .list-group-item.inactive span {
      color: #979797;
    }

    .list-group-item.active span {
      color: white;
      background-color: transparent !important;
    }
    .list-group-item.inactive span {
      color: #979797;
      background-color: transparent !important;
    }
  }
}
.white {
  background: white;
  color: black;

  .row {
    color: black;
  }
  :global {
    .list-group-item.active span {
      color: black;
    }
  }
}
