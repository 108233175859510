.page {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
}
.wrapper {
  width: 315px;
  padding: 30px;
  border-radius: 20px;
  background: white;
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  margin-bottom: 33px;
}
.list {
  list-style-type: none;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.buttonList {
  width: 22px;
  height: 22px;
  border: 1px solid rgb(93, 54, 189);
  padding: 3px;
  border-radius: 50%;
  margin-right: 18px;
}
.ButtonSelected {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgb(93, 54, 189);
}
.list li {
  font-family: 'Assistant';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  margin-bottom: 27px;
  display: flex;
  width: 100%;
}
.buttons {
  display: flex;
  justify-content: space-between;
}
.button {
  width: 122px;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  border-radius: 13px;
  justify-content: center;
  text-align: center;
  font-family: 'Assistant';
  font-size: 19px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: -0.16500000655651093px;
}
.buttonSoft {
  background: #dbcafe;
  color: rgb(93, 54, 189);
}
.buttonBold {
  background: #5d36bd;
  color: white;
}

.input {
  width: 100%;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px rgba(93, 54, 189, 0.3) solid;
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  outline: none;
  margin-bottom: 30px;
}
.heb {
  .buttonList {
    margin-left: 18px;
    margin-right: 0px;
  }
}
