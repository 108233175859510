.EditProfileTop {
  min-height: 164px;
}
.ProgressContainer {
  width: 140px;
  height: 140px;
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  margin-top: 20px;
}
.box {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.box_text {
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
}

.StrengthContainer {
  width: 100%;
  max-width: 340px;
}

.AdminEditButton {
  color: blue;
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
}

.box_text_title {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
}
.box_text_subTitle {
  margin-top: 17px;
  color: rgb(93, 54, 189);
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;

  span {
    font-family: Montserrat, Rubik;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
}
.heb {
  .box_text_subTitle span,
  .title {
    font-family: 'Rubik', sans-serif;
  }
}
