.wrapperBig {
  min-height: 100vh;
  position: relative;
}
.wrapper {
  padding: 20px 25px;
  padding-top: 90px;
  max-width: 780px;
  min-height: 100%;
}
.buttons {
  display: flex;
  justify-content: space-between;
}
.button {
  max-height: 400px;
  width: calc(50% - 6px);
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  label {
    width: 100%;
    height: 100%;
  }

  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.button .imgEmpty {
  object-fit: contain;
}
.text {
  color: #5d36bd;
  width: 100%;
  font-family: Assistant;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
  top: calc(50% + 4px);
  left: 0px;
}
.iconDelete {
  height: auto !important;
  width: auto !important;
  object-fit: none !important;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
}
.iconEdit {
  height: auto !important;
  width: auto !important;
  object-fit: none !important;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
}
.UsernameStateLoader {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 0px;
  bottom: 15px;
}
.heb {
  .UsernameStateLoader {
    left: 0px;
    right: auto;
  }
}
.inputs {
  margin-top: 30px;
}
.input {
  width: 100%;
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.4099999964237213px;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px rgb(219, 202, 254) solid;
  outline: none;
}
.inputDown {
  font-family: 'Assistant';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.16500000655651093px;
  color: rgba(0, 0, 0, 0.2);
  transition: 0.2s all;
  margin-bottom: 14px;
}
.hide {
  opacity: 0;
}
.tags {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.tag {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 8px 15px;
  border-radius: 16px;
  background: #f9f9f9;
  color: rgb(93, 54, 189);
  margin-right: 25px;
  margin-bottom: 25px;
}
.addTag {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgb(93, 54, 189);
  padding: 8px 15px;
  cursor: pointer;
  background: #dbcafe;
  border-radius: 16px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.heb {
  direction: rtl;

  .tag,
  .addTag {
    margin-right: 0px;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;

    .button {
      height: 153px;
    }
  }
}
