@import '../../../assets/scss/default/theme';

.seeMoreContainer {
  background-color: #0e091e;
  min-height: 100vh;
  padding: 0;
  overflow: hidden;

  .title {
    height: 50px;
    font-size: 2.5em;
    color: white;
    font-weight: 700;
    margin: 0 20px 30px;

    @media only screen and (min-width: 1200px) {
      & {
        max-width: 1200px;
        margin: auto;
      }
    }
  }
}
