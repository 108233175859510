@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

//desktop / all
.Master {
  max-width: $size-mobile;
  padding-top: 20px;
}
.title {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  letter-spacing: -0.41px;

  color: #111111;
}

.detail {
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.31px;

  /* basic black 50% */

  color: #888888;
}

.providerButton:first-child {
  margin-top: 0px;
}

.seperatorSection {
  margin-top: 40px;
  margin-bottom: 40px;
  .sep {
    /* Light blue #E3E3EE */
    width: 40%;
    height: 2px;
    background: #e3e3ee;
    border-radius: 74px;
  }

  .text {
    /* Web/Paragraph 18 px */

    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.165px;

    color: #000000;
  }
}

.OpenInboxButton {
  background: #5d36bd !important;
  border-radius: 20px !important;
  color: white !important;

  /* Purple #5D36BD */
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-transform: none !important;
  width: 260px;
  height: 64px !important;
  border-radius: 32px !important;

  div {
    /* Web/Big button 18px */

    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: -0.41px;

    color: #ffffff;
  }
}

.ResendButton {
  border-radius: 20px !important;

  /* Purple #5D36BD */
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-transform: none !important;
  width: 260px;
  height: 64px !important;
  border-radius: 32px !important;

  .button_title {
    /* Web/Big button 18px */

    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: -0.41px;

    color: #5d36bd;
  }
}

//laptop
@media #{$media-laptop} {
}
//TABLET
@media #{$media-tablet} {
}
//MOBILE
@media #{$media-phone} {
  .Master {
    > .title {
      width: 95%;
    }
  }
}
