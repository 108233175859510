.BookingPrice {
  max-height: 200px;
}
.title {
  width: 100%;
  text-align: center;
  font-family: Montserrat, Rubik;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.41px;
  color: #111111;
  margin-top: 50px;
}
.currency {
  font-size: 24px;
  color: black;
}
.heb {
  .inputRow {
    direction: ltr;
  }
}

.inputRow {
  margin: 80px 0px;
  position: relative;
}
.error {
  width: 260px;
  color: red;
  transform: translateX(-50%);
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: -28px;
}
.input {
  width: 230px;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-bottom: 2px #dbcafe solid;
  text-align: center;
  font-family: Montserrat, Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;

  &::placeholder {
    font-family: Montserrat, Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.4099999964237213px;
    text-align: center;
  }
}
.text {
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}
.shake {
  animation: shake 1s forwards ease-in;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-12px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}
.heb {
  .text {
    direction: rtl;
  }
  .title,
  .input {
    font-family: 'Rubik', sans-serif;
  }

  .input::placeholder {
    font-family: 'Rubik', sans-serif;
  }
}
@media only screen and (max-width: 1024px) {
  .inputRow {
    margin: 10px 0px 30px 0px;
  }
  .text {
    font-size: 12px;
  }
  .input {
    background: none;
  }
}

//mobile
@media only screen and (max-width: 767px) {
  .input {
    width: 130px;
  }
  .hideOnMobile {
    opacity: 0;
  }
  .text {
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 125%;
    /* or 15px */

    color: rgba(0, 0, 0, 0.3);
  }

  .title {
    margin-bottom: 5px;
  }
}
