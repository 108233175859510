@import '../assets/scss/default/mixins';
@import '../assets/scss/default/theme';

.heb {
  direction: rtl;
}
.Master {
  background: #0e091e;
}
//DESKTOP
.Profile {
  width: 100%;
  height: 100%;
  color: white;
  background: #0e091e;
  box-sizing: border-box;
  font-family: 'Assistant';
}
// .MobileProfile {
//   display: none;
// }

.ProfileBackgroundHidden {
  background: transparent !important;
  position: relative;
}

.mainContentWrapper {
  background: #0e091e;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.wrapper {
  background: #0e091e;
}
.close {
  z-index: 100;
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  transition: 0.2s all;
}
.header {
  width: 100%;
  z-index: 1002;
  position: fixed;
  top: 0px;
  left: 0px;
}
.headerSpacing {
  width: 100%;
  height: 75px;
}
.background {
  width: 100%;
  height: 100%;
  background: rgba(14, 9, 30, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;

  .imagePro {
    width: 100%;
    height: 100%;
    filter: blur(22px);
    object-fit: cover;
    opacity: 0.15;
  }
  .backgroundOverlay {
    width: 100%;
    height: 100%;
    background: rgba(14, 9, 30, 0.3);
    position: absolute;
    z-index: 2;
  }
}
.backgroundSmall {
  height: 100%;
}
.videoContainer {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
}
.videoBackground {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
}
.videoWrapper {
  max-height: 50%;
  border-radius: 12px;
  position: relative;

  video {
    height: calc(50vh);
    width: auto;
  }
}
.allVisible {
  opacity: 1;
  transition: 2s opacity;
  background: #0e091e !important;
  height: 100%;
}
