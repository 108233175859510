.BookingDescription {
  width: 100%;
}
.title {
  width: 100%;
  text-align: center;
  font-family: Montserrat, Rubik;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.41px;
  color: #111111;
  margin-top: 50px;
}
.input {
  width: 100%;
  max-height: 500px !important;
  height: 200px;
  // min-height: 100px;
  padding: 10px 0px;
  margin-top: 80px;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-bottom: 2px #dbcafe solid;
  text-align: center;

  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  text-align: left;

  &::placeholder {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.16500000655651093px;
  }
}
.text {
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 80px;
}
.minChar {
  width: 100%;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
  color: rgb(255, 190, 142);
}
.textError {
  color: red;
}
.shake {
  animation: shake 1s forwards ease-in;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-12px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}
.heb {
  direction: rtl;

  .minChar,
  .input {
    text-align: right;
  }
}
@media only screen and (max-width: 1024px) {
  .input {
    background: none;
    margin: 0px;
  }
  .text {
    margin-top: 30px;
    font-size: 12px;
  }
}

//mobile
@media only screen and (max-width: 767px) {
  .BookingDescription {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .input {
    background: none;
    margin: 0px;
    max-height: 70px !important;
  }
  .text {
    display: none;
    margin-top: 30px;
    font-size: 12px;
  }
}
