.MainContainer {
  height: 70px;
  background: rgba(219, 202, 254, 0.2);
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
  transition: 1s;
  border: 1px solid transparent;
  &:hover {
    transition: transform 0.15s ease-in-out;
    transform: scale3d(1.05, 1.05, 1);
  }
  cursor: pointer;
}
.ButtonTitle {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
}

.PriceTitle {
  font-family: Montserrat, Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;

  text-transform: capitalize;
}
.SideImage {
  width: 50px;
  height: 50px;
  background: rgba(219, 202, 254, 0.2);
  opacity: 0.9;
}
.SideImageCustom {
  width: 50px;
  height: 50px;
}

.GradientButtonOne {
  transition: 1s;
  background: linear-gradient(90deg, #cb8171 4.72%, #947fb7 109.77%);
}

.GradientButtonOneBig {
  transition: 1s;
  background: linear-gradient(90deg, #cb8171 4.72%, #947fb7 109.77%);
}
@media only screen and (max-width: 1024px) {
  .MainContainer {
    height: 70px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ButtonTitle {
    font-size: 16px;
  }

  .PriceTitle {
    font-size: 18px;
  }
}

.outline_btn {
  transition: 1s;
  border: 1px solid;
  background: transparent;
}
