.heb {
  .action_button {
    direction: ltr;
  }
}

.reuqestBoxContainer {
  display: inline-block;
  position: relative;
  background: #221c34;
  border-radius: 30px;
  width: 258px;
  height: 240px;
  margin-right: 15px;
  overflow: hidden;
  padding: 15px 20px;
  margin-bottom: 10px;

  @media only screen and (min-width: 767px) {
    & {
      width: 300px;
      height: 406px;
    }
  }

  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px;
  }

  .header {
    display: flex;

    .imageContainer {
      position: relative;

      img {
        width: 30px;
        height: 30px;
        border-radius: 9px;
      }

      .rankBadge {
        display: none;
      }
    }

    .username {
      display: none;
    }

    .name {
      display: block;
      color: white;
      font-size: 0.85em;
      font-weight: 700;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: -2px;
    }

    .title {
      display: block;
      color: white;
      opacity: 0.2;
      font-weight: 600;
      font-size: 0.7em;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: -2px;
    }

    @media only screen and (min-width: 767px) {
      & {
        display: initial;
        margin-top: 10px;
        text-align: center;

        .imageContainer {
          width: 104px;
          margin: auto;

          img {
            width: 104px;
            height: 104px;
            border-radius: 30px;
            margin-bottom: 15px;
          }

          .rankBadge {
            display: inherit;
            position: absolute;
            bottom: 6px;
            right: -8px;
            display: none;
          }
        }

        .name {
          font-size: 1.1em;
          font-weight: 700;
          margin-left: 10px;
          margin-top: -1px;
        }

        .title {
          opacity: 0.2;
          font-weight: 600;
          font-size: 0.8em;
        }

        .username {
          display: inherit;
          font-size: 0.8em;
          color: #dbcafe;
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
    }
  }

  .requestContent {
    padding: 0 20px;
    font-weight: 700;

    span {
      color: #ffffff;
      display: block;
      text-align: center;
      font-size: 1.1em;
      line-height: 1.2em;
      width: 100%;
      white-space: initial;
    }
  }

  .discover {
    width: 100%;
    margin: 0px;
  }

  button {
    padding: 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 700;
  }
}
