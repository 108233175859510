@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.Creations_box {
  width: calc(25% - 15px);
  margin-bottom: 25px;
  background: rgb(150, 150, 150);
  border-radius: 16px;
}
.Creations_boxBig {
  height: 205px;
}
.Creations_boxMedium {
  height: 150px;
}
.Creations_boxSmall {
  height: 103px;
}
.Creations_wrapper {
  height: 900px;
  // width:1860px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}
.responsiveWrapper {
  width: auto;
}
.Creations_title {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 40px;
  font-family: Montserrat, Rubik;
}
.appsText {
  width: 100%;
  font-size: 24px;
  font-family: Montserrat, Rubik;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
}
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.buttonFirst {
  margin-right: 20px;
  width: 180px;
}
.button {
  width: 180px;
}
.more {
  border-radius: 15px;
  height: 30px;
  border-color: white;
  border-width: 1px;
  font-family: 'Assistant';
  font-weight: 600;
  width: 100%;
  text-align: center;
  display: none;
}
// @media only screen and (max-width: 1900px) {
//     .Creations_wrapper{
//         width:1560px;
//     }
// }
// @media only screen and (max-width: 1600px) {
//     .Creations_wrapper{
//         width:1250px;
//     }
// }
@media only screen and (max-width: 1300px) {
  .Creations_wrapper {
    width: 930px;
  }
}
@media only screen and (max-width: 1024px) {
  .Creations_wrapper {
    padding-left: 00px;
    padding-right: 00px;
    width: 100%;
    height: auto;
    overflow: auto;
    flex-direction: row;
  }
  .white .Creations_wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .buttons,
  .appsText {
    display: none;
  }
  .button {
    margin-top: 20px;
  }
  .more {
    margin-bottom: 20px;
    display: block;
  }
  .Creations_title {
    padding-left: 00px;
    padding-right: 00px;
    margin-bottom: 10px;
    font-size: 24px;
  }
}
.heb {
  z-index: 1;
  .Creations_title {
    text-align: right;
    font-family: 'Rubik', sans-serif;
  }
  .appsText {
    direction: rtl;
    font-family: 'Rubik', sans-serif;
  }
  .more {
    direction: rtl;
  }
}

.default {
  // padding-left: 20px;
  // padding-right: 20px;
  z-index: 1;
}

.responsiveWrapper {
  width: auto;
}

//TABLET
@media #{$media-tablet} {
  .responsiveWrapper {
    width: 100%;
  }
}
//MOBILE
@media #{$media-phone} {
  .responsiveWrapper {
    width: 100%;
  }
}
