.loading{
    height:64px;
    width:64px;
    background:#5d36bd;
    // padding:40px;
    border-radius: 24%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
        width:34px;
    }
    :global .spinner-border{
        height: 3rem;
        width: 3rem;
    }
}
.percentage {
    font-size:12px;
    transform: translate(-50%, -50%);
    position: absolute;
    top:50%;
    left:50%;
}