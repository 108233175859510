@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.navbar {
  display: none;
}

//desktop / all
.Master {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.heb {
  direction: rtl;
}

.InitialSection {
  width: 100%;

  .PhoneEmailInputTextFieldContainer {
    max-width: $size-mobile;
    width: 100%;
  }
}
.title {
  /* Web/Small title 32px */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  letter-spacing: -0.41px;

  /* Purple #5D36BD */

  color: #5d36bd;
}

.detail {
  /* Web/Paragraph 18 px */

  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.165px;

  /* Dark #111111 */

  color: #111111;
}

.buttonContainer {
  width: 100%;
  margin-top: 40px;
  .providerButton {
    border: 1px solid #518ef8;
    box-sizing: border-box;
    border-radius: 100px;
    height: 64px;
    margin-top: 10px;

    img {
      height: 24px;
      width: 24px;
      border-radius: 0px;
    }

    .title {
      /* Web/Big button 18px */

      text-transform: none;
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 120%;
      /* identical to box height, or 22px */

      text-align: center;
      letter-spacing: -0.41px;

      /* Inside Auto Layout */

      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 10px;
    }
  }
}

.providerButton:first-child {
  margin-top: 0px;
}

.seperatorSection {
  margin-top: 40px;
  margin-bottom: 40px;
  .sep {
    /* Light blue #E3E3EE */
    width: 40%;
    height: 2px;
    background: #e3e3ee;
    border-radius: 74px;
  }

  .text {
    /* Web/Paragraph 18 px */

    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.165px;

    color: #000000;
  }
}
.actionButton {
  /* Purple #5D36BD */
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-transform: none !important;
  width: 260px;
  background: #5d36bd !important;
  height: 64px !important;
  border-radius: 32px !important;

  div {
    /* Web/Big button 18px */

    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: -0.41px;

    color: #ffffff;
  }
}

.inviteCodeContainer {
  /* White */

  background: #ffffff;
  /* Basic shadow 20% */

  box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.2);
  border-radius: 30px;

  .icon {
    width: 60px;
    height: 60px;

    /* Purple #5D36BD */

    background: #5d36bd;
  }

  .title {
    /* Web/Small heading 18px */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.165px;

    color: #111111;
  }

  .detail {
    /* Web/Sidenote 14 px */

    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    letter-spacing: -0.165px;

    color: rgba(17, 17, 17, 0.5);
  }
}

.PhoneInputField {
  .inputField {
    /* Web/Paragraph 18 px */

    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.165px;

    /* Dark #111111 */

    color: #111111;

    /* Inside Auto Layout */

    border-width: 0px !important;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
  }

  .separator {
    margin-top: 4px;
    height: 2px;
    background: rgba(93, 54, 189, 0.3);
    border-radius: 74px;
  }
}

//laptop
@media #{$media-laptop} {
}
//TABLET
@media #{$media-tablet} {
}
//MOBILE
@media #{$media-phone} {
  .closeButton {
    text-align: center;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    background: #f9f9f9;
    img {
      width: 22px;
      height: 22px;
    }
  }
}
