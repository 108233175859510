@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.Links {
  display: flex;
  justify-content: center;
}
.Links_item {
  // width: 91px;
  padding: 0px 20px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 600;
  color: white;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 12px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;

  img {
    height: 16px;
    margin-right: 6px;
  }

  &:hover {
    border: 1px solid #5d36bd;
    background: rgba(255, 255, 255, 0.1);
    transform: scale(1.05);
  }
}
@media only screen and (max-width: 1024px) {
  .Links {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  .Links_item {
    padding: 7px 12px;
    margin-right: 0px;
    font-size: 12px;
    margin-bottom: 15px;
    margin-right: 6px;
  }
}
.heb {
  .Links_item {
    direction: rtl;
  }
  .Links_item img {
    margin-right: 0px;
    margin-left: 6px;
  }
}
