@import '../assets/scss/default/mixins';
@import '../assets/scss/default/theme';

.authDialog {
  width: 100%;
  box-shadow: 8px 16px 36px rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  background-color: #fff;
  padding: 50px;
  position: relative;
  overflow: hidden;
}

.ctaButton {
  background-color: $purple;
  color: $white;
  padding: 20px 70px;
  border: none;
  border-radius: 16px;
  margin: 0 auto;
  display: block;

  @include transition(all $fast $easing);

  &:hover {
    background-color: darken($purple, 5%);
  }

  span {
    font-weight: 700;
    font-size: 18px;
  }

  @media #{$media-phone} {
    margin: 0;
    width: 100vw;
    border-radius: 0;
  }
}

.inputBase {
  width: 100%;
  border: none;
  border-bottom: 1px solid $purple-washed;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
  font-family: inherit;

  &::placeholder {
    color: transparentize($black, 0.8);
  }
}

.errorBase {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 80%;
  color: $red;
  @include transform(translate(0, calc(100% + 5px)));
}

.authDialog {
  width: 460px;
  border-radius: 30px;
  background-color: #fff;
  padding: 50px;
  position: relative;
  overflow: hidden;

  @media #{$media-phone} {
    width: 90vw;
    padding: 0;
    border-radius: 0;
  }
}

.authStep {
  header {
    @media #{$media-phone} {
      text-align: center;
    }

    h5 {
      color: $purple;
      font-size: 32px;
      font-weight: 700;
      margin-block-end: 10px;
      white-space: nowrap;

      @media #{$media-phone} {
        font-size: 24px;
      }
    }

    p {
      color: $black;
      font-weight: 600;
      font-size: 18px;

      @media #{$media-phone} {
        font-size: 14px;
      }
    }
  }
}

.dialogFooter {
  display: block;

  @media #{$media-phone} {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $white;
    //border-top: 1px solid transparentize($black, .9);
    //box-shadow: 0 -1px 10px 0 transparentize($black, .9);
  }
}

.errorWrapper {
  display: block;
  text-align: center;

  @media #{$media-phone} {
    font-size: 14px;
  }
}

.baseBody {
  margin-block-start: 30px;

  @media #{$media-phone} {
    margin-block-start: 3vw;
  }
}

.activeBox {
  @include transform(scale(1) !important);
  background-color: $purple !important;
  color: $white !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
}

.userGoalBody {
  @extend .baseBody;

  padding-block-start: 50px;
  padding-block-end: 50px;

  @media #{$media-phone} {
    padding-block: 5vw;
  }

  h5 {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
    padding: 0 30px;
    margin-block-start: 30px;

    @media #{$media-phone} {
      padding: 0;
    }

    dd {
      background-color: $purple-grey;
      color: $purple;
      border-radius: 16px;
      padding: 20px;
      position: relative;
      font-weight: 700;
      cursor: pointer;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

      @include transition(all $fast $easing);
      @include transform(scale(0.8));
      @include transform-origin(center);

      &::before {
        content: '';
        padding-bottom: 50%;
        position: static;
        display: block;
      }

      span {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2em;
        display: block;

        @include transform(translate(0, 0));
        @include transition(all $fast $easing);
      }

      &:hover {
        @include transform(scale(0.85));
      }

      // @see .activeBox
    }
  }

  > p {
    color: $grey-washed;
    font-size: 14px;
    font-weight: 600;
    font-family: $secondary-font;
    text-align: center;
    margin-block-start: 30px;
  }
}

.createAccountBody {
  @extend .baseBody;

  > div {
    margin-block-end: 30px;
    display: block;
    position: relative;

    @media #{$media-phone} {
      margin-block-end: 5vw;
    }

    input {
      @extend .inputBase;
    }

    span // Error wrapper
    {
      @extend .errorBase;
    }
  }
}

.verifyPhoneNumberBody {
  @extend .baseBody;
  margin-block-start: -15px;

  > strong {
    @media #{$media-phone} {
      display: block;
      margin: 0 auto;
      width: fit-content;
    }
  }
}

.codeWrapper {
  margin-block-end: 70px;
  margin-block-start: 70px;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;

  input {
    @extend .inputBase;
    text-align: center;
  }
}

.resendWrapper {
  text-align: center;

  button {
    background: none;
    background-color: transparent;
    border: none;
    margin-block-end: 180px;

    span {
      color: $purple;
      font-family: $secondary-font;
      font-weight: 700;
      font-size: 18px;
      @include transition(all $fast $easing);
    }

    &:hover {
      span {
        color: darken($purple, 5%);
      }
    }

    @media #{$media-phone} {
      margin-block-end: 0;
    }
  }
}

.socialNetworksBody {
  @extend .baseBody;

  section {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-column-gap: 17px;
    align-items: center;
    margin-block-end: 30px;
    position: relative;

    @media #{$media-phone} {
      margin-block-end: 5vw;
    }

    label {
      margin: 0;
      padding: 0;
    }

    input {
      @extend .inputBase;
    }

    i {
      position: absolute;
      right: 5px;
      top: 50%;
      @include transform(translate(0, -50%));
    }

    span {
      @extend .errorBase;
    }
  }
}

.uploadPictureBody {
  @extend .baseBody;

  .manualUpload {
    margin-block-end: 50px;

    @media #{$media-phone} {
      margin-block-end: 7vw;
    }

    > div {
      width: 60%;
      @include display-flex(center, space-around);
      margin: 0 auto;

      button {
        background: none;
        border: none;
        background-color: transparent;
        position: relative;
        overflow: hidden;

        input {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          cursor: pointer;
        }

        figure {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          border: 1px dashed $purple-washed;
          background-color: $grey-bright;
          margin: 0 auto 5px;
          position: relative;
          @include transition(all $fast $easing);
          user-select: none;

          img {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0.3;
            pointer-events: none;
            @include transition(all $fast $easing);
            @include transform(translate(-50%, -50%));
          }
        }

        span {
          color: $black;
          font-size: 12px;
          font-weight: 700;
          font-family: $secondary-font;
          opacity: 0.7;
          display: block;
          @include transition(all $fast $easing);
        }

        &:hover {
          figure {
            border-color: $purple;

            img {
              opacity: 1;
            }
          }

          span {
            opacity: 1;
          }
        }
      }
    }
  }

  .naming {
    margin-block-end: 50px;

    input {
      @extend .inputBase;
    }
  }
}

.previewImage {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 0;
  right: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  object-fit: cover;
  border-radius: 25%;
}

.previewImageAfter {
  content: ' ';
  background: linear-gradient(#25252500, #25252560);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
}

.setPricesBody {
  > section {
    box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.1);
    background-color: $white;
    padding: 20px 15px;
    border-radius: 23px;
    display: grid;
    grid-template-columns: 70% 1fr;
    align-items: center;
    margin-block-end: 15px;
    grid-column-gap: 10px;

    @media #{$media-phone} {
      box-shadow: none;
      border-bottom: 1px solid rgba(118, 138, 149, 0.1);
      border-radius: 0;
      padding: 5vw 0;
      margin: 0;
    }

    header {
      div {
        display: grid;
        grid-template-columns: 24px 1fr;
        grid-column-gap: 10px;

        figure {
          height: 24px;
        }

        span {
          color: $purple;
          font-family: $secondary-font;
          font-size: 18px;
          font-weight: 600;
        }
      }

      p {
        font-family: $secondary-font;
        font-size: 14px;
        color: $grey-washed;
        margin-block-start: 10px;
      }
    }

    input {
      @extend .inputBase;
      text-align: center;
      font-size: 24px;
    }
  }
}

.creationDescBody {
  @extend .baseBody;

  > section {
    margin-block-end: 30px;
    position: relative;

    textarea {
      background-color: $grey-bright;
      border: none;
      resize: none;
      width: 100%;
      height: 440px;
      border-radius: 24px;
      padding: 30px 20px;
      font-size: 18px;
      font-weight: 600;
      color: $black;

      @media #{$media-phone} {
        height: 95vw;
      }

      &::placeholder {
        color: $grey-washed;
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 600;
      }
    }

    > div {
      background-color: transparentize($purple, 0.8);
      padding: 10px 15px;
      border-radius: 9px;
      display: inline-grid;
      grid-template-columns: repeat(2, 24px);
      grid-column-gap: 30px;
      pointer-events: none;
      position: absolute;
      bottom: 30px;
      left: 50%;

      @include transition(all $fast $easing);
      @include transform(translate(-50%, 15px));
      opacity: 0;
      $buttonSize: 24px;

      button {
        background: none;
        background-color: transparent;
        border: none;
        width: $buttonSize;
        height: $buttonSize;
        padding: 0;

        figure {
          padding: 0;
          margin: 0;
          width: $buttonSize;
          height: $buttonSize;
          opacity: 0.7;
          @include transition(all $fast $easing);

          img {
            width: $buttonSize;
            height: $buttonSize;
          }
        }

        &:hover {
          figure {
            opacity: 1;
          }
        }
      }
    }
  }
}

.sectionFocus {
  @include transform(translate(-50%, 0) !important);
  opacity: 1 !important;
  pointer-events: all !important;
}

.shareProfileBody {
  @extend .baseBody;

  > section {
    $imageSize: 24px;

    display: grid;
    grid-template-columns: 1fr $imageSize;
    grid-column-gap: 10px;
    margin-block-end: 40px;

    @media #{$media-phone} {
      grid-template-columns: 1fr 20vw;
      margin-block-end: 5vw;
      grid-column-gap: 0;
    }

    button {
      background-color: transparent;
      background: none;
      border: none;
      position: relative;
      padding: 0;
      margin: 0;
      width: fit-content;
      white-space: nowrap;

      &::after {
        content: attr(data-tooltip);
        position: absolute;
        top: 0;
        left: 50%;
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: 600;
        background-color: transparentize($black, 0.2);
        color: $white;
        padding: 2px 10px;
        border-radius: 4px;
        opacity: 0;
        pointer-events: none;

        @include transform(translate(-50%, calc(-80% + 5px)));
        @include transition(all $fast $easing);
      }

      &::before {
        content: '';
        border: 5px solid transparentize($black, 0.2);
        border-color: transparentize($black, 0.2) transparent transparent
          transparent;
        position: absolute;
        top: 0;
        left: 50%;
        height: 5px;
        opacity: 0;
        @include transition(all $fast $easing);
        @include transform(translate(-50%, 10px));
        pointer-events: none;
      }

      &:hover {
        &::after {
          @include transform(translate(-50%, -80%));
          opacity: 1;
        }

        &::before {
          @include transform(translate(-50%, 5px));
          opacity: 1;
        }
      }

      figure {
        width: $imageSize;
        height: $imageSize;

        img {
          width: $imageSize;
          height: $imageSize;
        }
      }

      @media #{$media-phone} {
        margin: 0 auto;
        display: block;
      }
    }

    input {
      @extend .inputBase;
    }
  }

  > nav {
    @media #{$media-phone} {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 5vw));
      grid-column-gap: 5vw;
      grid-row-gap: 5vw;
    }

    a {
      display: block;
      width: 80%;
      border: 2px solid $grey-washed;
      padding: 15px 40px;
      margin: 0 auto 15px;
      border-radius: 12px;
      text-align: center;

      @media #{$media-phone} {
        margin: 0;
        width: 100%;
        padding: 15px 0;
      }

      span {
        font-size: 18px;
        font-weight: 600;
        font-family: $secondary-font;
      }
    }
  }
}

.dropzone {
  margin-block-start: 50px;
  margin-block-end: 30px;

  @media #{$media-phone} {
    margin-block-start: 7vw;
    margin-block-end: 5vw;
  }

  > div {
    background-color: $grey-bright;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 25%;
    position: relative;
    overflow: hidden;

    @media #{$media-phone} {
      width: 180px;
      height: 180px;
    }

    figure {
      width: 78px;
      height: 78px;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      user-select: none;

      @media #{$media-phone} {
        width: 58px;
        height: 58px;
      }

      img {
        pointer-events: none;
      }
    }

    //hide input
    > input {
      @extend .inputBase;
    }
  }
}

.resetButton {
  cursor: pointer;
}

.stepsCounter {
  text-align: center;
  margin-block-end: 10px;

  i {
    background-color: $pink;
    height: 6px;
    width: 10px;
    border-radius: 4px;
    display: inline-block;
    margin: 0 3px;

    @include transition(all $fast $easing);
    @include transform-origin(center);
    @include transform(scale(1, 1));
  }
}

.activeStep {
  width: 22px !important;
  background-color: $purple !important;
}

.createAccountCTA {
  margin-block-start: 70px;

  @media #{$media-phone} {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 70px 0 0;
    width: 100%;
  }
}

.checkbox {
  display: grid !important;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 10px;
  position: relative;

  &.checked {
    label {
      &::before {
        background: $purple-washed url('./following_checkmark.svg') center
          center no-repeat;
      }
    }
  }

  input {
    opacity: 0;
  }

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    &::before {
      content: '';
      top: 4px;
      position: absolute;
      left: 0;
      border: 2px solid $purple-washed;
      border-radius: 4px;
      width: 25px;
      height: 25px;
    }
  }
}

.kre8LinkInput {
  display: grid;
  grid-template-columns: 25px 1fr 10fr;
  grid-column-gap: 10px;
  align-items: center;

  figure {
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  em {
    color: #000000;
    font-size: 16px;
    text-transform: none;
    font-style: normal;
    font-weight: 600;
  }
}

.CTA {
  @extend .ctaButton;
}

.secondaryCTA {
  color: $purple;
  font-size: 18px;
  font-weight: 700;
  font-family: $secondary-font;
  margin: 15px auto 0;
  display: block;
  text-align: center;
  background: none;
  border: none;
  width: fit-content;

  @media #{$media-phone} {
    margin-block-end: 15px;
    position: fixed;
    top: 0;
    left: 3.5vw;
  }
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparentize($black, 0.2);
  user-select: none;
  @include display-flex(center, center);

  svg {
    user-select: none;
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .authDialog {
    padding-top: 40px;
    border-radius: 0px;
    width: 100vw;
    height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    .authStep {
      height: 100%;
    }
  }
}
