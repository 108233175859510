.Channel {
  z-index: 1;
  width: 100%;
  height: 450px;
  margin-top: 40px;
  box-sizing: border-box;
  border-radius: 24px;
  transition: 0.2s all;
  background: rgba(255, 255, 255, 0.2);
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  margin-bottom: 200px;
  position: relative;
  top: 50px;
}
.phoneWrapper {
  // height: 382px;
  // width: 256px;
  // overflow: hidden;
  // position: absolute;
  // right: 360px;
  // bottom: 0px;
  // left: 0;
}
.Channel_iphone_with_video {
  position: absolute;
  bottom: 0px;
  height: 382px;
  width: 256px;

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 382px;
    width: 256px;
  }
  img {
    height: 382px;
    width: 256px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.Channel_iphone1 {
  left: 0;
  right: 0;
  position: relative;
  z-index: 2;
  height: 492px;
  width: 242px;
}

.video {
  border-radius: 20px 20px 0px 0px;
  background: black;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.Channel_title {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: bold;
  font-family: Montserrat, Rubik;
}
.v {
  width: 20px;
  margin-right: 12px;
}
.row {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 10px;
  float: left;
}
.rowLast {
  margin-bottom: 30px;
}
.text {
  width: calc(100% - 35px);
  float: right;
}
.bottom {
  display: flex;
  position: relative;
  top: 50px;
  float: left;
}
.rows {
  width: 100%;
  top: 30px;
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: -0.4px;
}
.price {
  font-size: 18px;
  font-weight: bold;
  color: #ffc69c;

  span {
    font-size: 36px;
  }
}
.buy {
  width: 260px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  border-radius: 16px;
  background: #5d36bd;
  color: white;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: 0.2s all;
  margin-left: 20px;

  &:hover {
    box-shadow: 0px 6px 16px rgb(40, 40, 40);
    transform: scale(1.05);
  }
}
@media only screen and (max-width: 1320px) {
  .Channel_iphone_with_video {
    display: none;
  }
  .Channel_iphone1 {
    right: 0px;
    display: block;
  }
  .phoneWrapper {
    right: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .Channel {
    display: none;
    margin-bottom: 90px;
    padding: 30px;
    // min-height: 590px;
    min-height: auto;
    height: auto;
    margin-top: 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.4px;
    float: left;
  }
  .rows {
    top: 0px;
    bottom: 25px;
  }
  .Channel_title {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .bottom {
    display: block;
    top: 0px;
    margin-top: 10px;
    margin-bottom: 5px;
    float: initial !important;
  }
  .price {
    text-align: center;
    margin-bottom: 20px;
  }
  .Channel_iphone_with_video {
    display: none;
  }
  .buy {
    margin-left: 0px;
  }
  .Channel {
    top: 0px;
  }
  .video {
    display: none;
  }
  .phoneWrapper {
    display: none;
  }
}
.heb {
  .Channel_title {
    font-family: 'Rubik', sans-serif;
    text-align: right;
  }
  .text {
    direction: rtl;
    text-align: right;
    float: left;
  }
  .Channel_iphone_with_video {
    // left: 0px;
  }
  .Channel_iphone1 {
    // left: 360px;
  }
  .v {
    margin-right: 0px;
    margin-left: 12px;
  }
  .bottom {
    float: right;
  }
  .price {
    direction: rtl;
  }
  .video {
    left: 11px;
    right: initial;
  }
  // .phoneWrapper {
  //   right: initial;
  //   left: 370px;
  // }
}
@media only screen and (max-width: 1320px) {
  .heb {
    .Channel_iphone1 {
      left: 0px;
      right: initial;
      display: block;
    }
    .phoneWrapper {
      left: 40px;
    }
  }
}
