.creatorContainer {
  display: inline-block;
  position: relative;
  min-height: 150px;
  overflow: hidden;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    transition: transform 0.15s ease-in-out;
    transform: scale3d(1.05, 1.05, 1);
  }

  .imageContainer {
    position: relative;
    height: 150px;
    width: 150px;
    background: linear-gradient(
      0deg,
      rgba(93, 54, 189, 0.2),
      rgba(93, 54, 189, 0.2)
    );
    overflow: hidden;
    min-height: 150px;
    border-radius: 30px;
    margin: auto;

    img {
      background: linear-gradient(
        0deg,
        rgba(93, 54, 189, 0.2),
        rgba(93, 54, 189, 0.2)
      );
      width: 150px;
      height: 150px;
    }
  }

  span {
    color: white;
    display: block;
    white-space: nowrap;
    width: 150px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
  }

  .creatorName {
    font-family: 'kre8font';
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-top: 5px;

    @media only screen and (max-width: 767px) {
      & {
        font-size: 18px;
      }
    }
  }

  .creatorTitle {
    font-size: 14px;
    line-height: 1.2em;
    min-height: 1.2em;
    text-align: center;
    color: #ae9bde;

    @media only screen and (max-width: 767px) {
      & {
        font-size: 14px;
      }
    }
  }
}

.creatorContainerDiscover {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
