.BookingBox {
  border: 2px solid #5d36bd;
  border-radius: 18px;
  padding: 20px;
  margin-top: 15px;

  &:first-child {
    margin-top: 0px;
  }

  .main {
    width: 100%;
  }
}
.title {
  font-family: Montserrat, Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #5d36bd;
}
.profile {
  margin-top: 10px;
  align-items: center;
}
.text {
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #000000;
}
.checkbox {
  display: flex;
  align-items: right;
  flex-direction: column;

  label:after {
    height: calc(1rem - 4px) !important;
    top: calc(0.25rem + 2px) !important;
  }
}
.image {
  width: 52px;
  height: 52px;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 10px;
}
.imageIcon {
  object-fit: contain;
  border-radius: 0px;
  width: 42px;
  padding-right: 10px;
}
.checkbox_text {
  font-family: Assistant;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-align: right;
  color: #000000;
}
.checkboxBootstrap {
  display: flex !important;
  justify-content: flex-end;
  transform: scale(1.7);
  margin-top: 25px;
  position: relative;
  right: 15px;
}
.heb {
  flex-direction: row-reverse;

  .title {
    direction: rtl;
    font-family: 'Rubik', sans-serif;
  }

  .profile {
    direction: rtl;
  }

  .image {
    margin-right: 0px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .BookingBox {
    border: 1px solid #5d36bd;
    padding: 14px;
  }
  .title {
    font-size: 12px;
  }
  .text,
  .checkbox_text {
    font-size: 12px;
  }
  .image {
    width: 36px;
    height: 36px;
  }
  .checkboxBootstrap {
    margin-top: 11px;
    transform: scale(1.4);
    right: 4px;
  }

  .heb {
    .checkboxBootstrap {
      right: 35px;
    }
  }
}
