@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

//DESKTOP
.Master {
}
//TABLET
@media #{$media-tablet} {
  .Master {
  }
}
//MOBILE
@media #{$media-phone} {
  .Master {
    width: 120px;
    height: 120px;

    .active {
      background: #ffffff;
      border: 1px solid #5d36bd;
      box-sizing: border-box;
      border-radius: 23px;
    }

    .notActive {
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(118, 138, 149, 0.1);
      border-radius: 23px;
    }

    img {
      width: 50px;
      height: 33px;
    }
    .title {
      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      /* or 19px */

      display: flex;
      align-items: center;

      color: #000000;
    }
  }
}
