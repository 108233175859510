@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
.App {
  text-align: center;
}

#root {
  --PhoneInputCountryFlag-borderColor: white !important;
  --PhoneInputCountryFlag-backgroundColor--loading: white !important;
  --PhoneInputCountryFlag-borderColor--focus: white !important;
}

:root {
  --primaryColor: #5d36bd;
  --primaryLight: #dbcafe;
  --secondaryColor: #ff6b00;
  --secondaryLight: #ffc69c;
  --tertiaryColor: #bf3698;
  --quaternaryColor: #bf3654;
  /* --fontFamily: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}
.show-white-space {
  white-space: pre-wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.action-button {
  font-weight: 700;
}
span-vertical {
  height: auto;
  min-height: 100px;
  display: inline-flex;
  align-items: center;
  border: 1px solid aqua;
}
.cr-buffer {
  height: 30px;
}

.cr-nav-bar-height {
  height: 55px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#user-detail-full-name-label {
  font-size: 20px !important;
}
#user-detail-title-label {
  font-size: small !important;
}
#social-container-row {
  display: block;
  margin: auto;
  padding: 0px;
  height: 60px;
  max-width: 420px;
  width: 100% !important;
  margin-bottom: 8px;
  text-align: center;
}
.user-card-title {
  font-size: 14px;
}
.section-header {
  font-size: 20px;
  font-weight: 600;
}
.tab-item-image {
  width: 30px;
  height: 30px;
}

.cr-tab-selected {
  background-color: whitesmoke;
  color: #000000;
}

.cr-tab-normal {
  background-color: whitesmoke;
  color: #000000;
  opacity: 100;
}
.flip-image-horiz {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
#session-price-container {
  width: 300px !important;
}
.create-session-input-explainers {
  font-size: 20px !important;
}
.create-session-input {
  font-size: 20px !important;
}

.cr-visible-tab-container {
  padding: 0 !important;
  height: 100%;
  max-width: 100% !important;
}
.cr-invisible-tab-container {
  display: none;
  padding: 0 !important;
}
.profile-action-buttons {
  font-size: 15px;
}
.opacity-30p {
  opacity: 0.3;
}
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 0.1;
}
.opacity-2 {
  opacity: 0.3;
}
.opacity-3 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: 0.8;
}
.opacity-5 {
  opacity: 1;
}
.cr-button-rect {
  height: 45px !important;
}
.nav-bar-title-center {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.text-dark {
  color: #000000;
}

.cr-table-divider {
  width: 100%;
  height: 0.3px;
  background-color: gray;
}
.card-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  float: left;
  display: block;
}
.card-label {
  font-size: 13px;
  font-weight: 300;
  position: relative;
  display: block;
  float: left;
}

.social-column {
  width: 33.3%;
  float: left;
  font-size: 13px;
  font-weight: 600;
}

.social-label {
  float: left;
}

.card-border-radius {
  border-radius: 25px;
}
.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
  border-radius: 20rem;
}
.toggle.ios .toggle-handle {
  border-radius: 20rem;
}
.small-toggle-switch {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
  border-radius: 20rem !important;
}
.jumbo-card-container {
  width: 90%;
  max-width: 1120px;
}
.create-page-container-medium {
  width: 100%;
  max-width: 800px;
}
​ .loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  display: inline-block;
  position: absolute;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  left: 41%;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primaryColor) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.nav-bar-drop-down-button {
  position: relative !important;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.vh-100 {
  height: 100vh;
}
.bg-purple {
  background-color: var(--primaryColor) !important;
}
.profile-bg-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.post-cell-username {
  font-size: 12px;
}

.post-cell-usertitle {
  font-size: 12px;
}
.post-cell-title {
  font-size: 16px;
  line-height: 16px;
}

.profile-image-bg-gradient {
  padding: 2px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
}

.hidden {
  display: none;
}

.center {
  margin: auto;
  width: 60%;
  padding: 10px;
}

.center-no-padding {
  margin: auto;
  width: 60%;
}

.circular {
  margin-bottom: -30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.circular img {
  text-align: center;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.circular-medium {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.profile-pic-medium {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.circular-medium img {
  text-align: center;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#post-title {
  padding-left: 5%;
  padding-right: 5%;
  color: #e588ff;
  height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'Assistant', Roboto;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
#user-handle {
  color: #ffffff;
  height: auto;
  margin-top: -8px;
  font-family: 'Assistant', Roboto;
  font-size: 15px;
  font-weight: 600;
}

#user-name {
  color: #ffffff;
  margin-top: -1px;
  height: auto;
  font-family: 'Assistant', Roboto;
  font-size: 11px;
  font-weight: 500;
}

#user-title {
  color: #ffffff;
  margin-top: 4px;
  margin-bottom: 5px;
  height: auto;
  font-family: 'Assistant', Lato, Roboto;
  font-weight: 500;
  font-size: 15px;
}

#info-container-card {
  bottom: 50px;
}
.cr-action-button {
  z-index: 5;
  border: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 50px;
  bottom: 0;
  background-color: var(--primaryColor);
  color: #ffffff;
  font-family: 'Assistant', Roboto;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.cr-action-button-success {
  z-index: 5;
  border: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 50px;
  bottom: 0;
  background-color: #28a745;
  color: #ffffff;
  font-family: 'Assistant', Roboto;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.cr-action-button-fluid {
  z-index: 5;
  border: 0;
  width: 100%;
  height: 50px;
  background-color: var(--primaryColor);
  color: #ffffff;
  font-family: 'Assistant', Roboto;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.cr-subtitle {
  font-size: 11px;
}

.cr-full-screen-overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
}
.full-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

.cr-action-button-bottom-personalized-requests {
  border: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  height: 70px;
  background-color: var(--primaryColor) !important;
  color: #ffffff;
  font-family: 'Assistant', Roboto !important;
  font-size: 20px !important;
  border-color: #ffffff !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}
.badge-purple {
  color: #fff !important;
  background-color: var(--primaryColor);
}
.badge-purple[href]:hover,
.badge-purple[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #4b27c8 !important;
}
#overlay-profile-button {
  left: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.cr-navbar-right {
  float: right !important;
  position: absolute !important;
}

.cr-nav-bar {
  height: 60px;
  width: 100%;
}

.cr-table-bg {
  background-color: #efeff4;
}

.cr-nav-bar-buttons {
  background-color: #000000;
  text-align: center;
  color: #ffffff;
}

#user-profile-picture {
  background-color: #000000;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.category-row-title-font {
  font-weight: 700;
  font-size: large !important;
}
.object-fit-fill {
  object-fit: fill !important;
}
.profile-body {
  background-color: #f2f2f5;
  margin: 0;
  height: 100%;
}

.social-asset-picture {
  height: 20px;
  width: 20px;
}

.info-container {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 5;
  overflow: auto;
  widows: 50%;
  max-height: 30%;
  text-align: center;
}
.panel-top {
  min-height: 130px;
  top: 0px !important;
  margin-top: 15px;
  bottom: undefined !important;
  position: relative !important;
}

#close-circle-subscription-button {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 50px;
}

.account-table-row {
  display: inherit;
  height: 60px;
}

.row-title {
  height: auto;
}

.table-header-title {
  display: inline-block;
  text-align: left;
}

#info-container-card-bg {
  border-radius: 4px 4px 0px 0px;
  padding-bottom: 5px;
  padding-top: 35px;
  height: 100%;
  width: 100%;
}

#top-card-corners {
  border-radius: 4px 4px 0px 0px;
}

.payment-methods-container {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 40%;
  height: auto;
  text-align: left;
}

#info-container-profile {
  top: 0;
  left: 0;
  margin-top: 14%;
  position: absolute;
  border-radius: 4px 4px 0px 0px;
  padding-bottom: 5px;
  padding-top: 15px;
  height: 20%;
  width: 100%;
}

.profile-container {
  border-radius: 15px;
  max-width: 420px;
  width: 100%;
  height: 100%;
  position: relative;
}
.bg-black {
  background-color: black;
}
.bg-gray {
  background-color: #efeff4;
}
.btn-create {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  color: white !important;
}

.badge-personal {
  background-color: var(--primaryColor) !important;
  color: white !important;
}
.badge-business {
  background-color: #292b2c !important;
  color: white;
}

.profile-container-responsive {
  max-width: 100%;
  position: relative;
}
.download-app-buttons {
  height: 80px;
  width: 100%;
}
.navbar-buttons {
  right: 60px !important;
}
.download-app-buttons-small {
  height: 60px;
  width: auto;
}
.image-fit {
  object-fit: contain;
}

.image-fill {
  object-fit: cover;
}
.white-svg {
  fill: white;
}
.profile-card-container {
  height: 100%;
  max-width: 100% !important;
  border-radius: 15px;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.proflie-quantifiers-detail-label {
  font-size: 15px;
}
.profile-screen-background-image {
  opacity: 0.3;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.profile-info-container {
  z-index: 1000;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.profile-video-card-container {
  max-width: 620px !important;
  width: 100% !important;
  position: relative;
}
.circle-image {
  width: 50%;
  height: 50%;
}
.circle-image img {
  position: absolute;
}
.content-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.phone-body {
  overflow: hidden;
  border-radius: 15px;
  max-width: 420px;
  width: 100%;
  height: 100%;
  position: relative;
}
.cf-image {
  max-width: 75%;
  height: auto;
}

.phone-body-responsive {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  position: relative;
}

.info-container-children {
  display: block;
  margin: auto;
  text-align: center;
  color: #ffffff;
}

.info-container-children-dark {
  display: block;
  margin: auto;
  color: #000000;
}

.bg-black {
  background-color: #000000 !important;
}
.text-purple {
  color: var(--primaryColor) !important;
}

.btn-purple {
  background: var(--primaryColor) !important;
  color: white !important;
}

.btn-kr-primary {
  background: var(--primaryColor) !important;
  color: white !important;
}

.btn-kr-primary-light {
  background: var(--primaryLight) !important;
  color: white !important;
}

.btn-kr-secondary {
  background: var(--secondaryColor) !important;
  color: white !important;
}

.btn-kr-secondary-light {
  background: var(--secondaryLight) !important;
  color: white !important;
}

.btn-kr-tertiary {
  background: var(--tertiaryColor) !important;
  color: white !important;
}

.btn-kr-quaternary {
  background: var(--quaternaryColor) !important;
  color: white !important;
}

#profile-video-view {
  left: 0;
  bottom: 0;
  background: transparent;
  pointer-events: none;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#jumbo-banner-video-view {
  background: transparent;
  pointer-events: none;
  object-fit: cover;
  width: 100%;
  height: 100% !important;
  max-height: 450px !important;
}

.post-cell-video-view {
  background: gray;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.session-card-cell-user-image {
  height: 36px;
  width: 36px;
  bottom: 5px;
  object-fit: cover;
}

.bottom-position-fill {
  position: absolute;
  bottom: 0;
}

.bg-image {
  /* Add the blur effect */
  filter: blur(12px);
  -webkit-filter: blur(12px);

  /* Full height */
  height: 100%;
  width: 100%;

  bottom: 0;
  left: 0;
  position: absolute;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image-blur-strong {
  /* Add the blur effect */
  filter: blur(40px);
  -webkit-filter: blur(40px);
}

.bg-post-blocker-dark {
  background-color: #80000000;
}

.bg-image-post-cell {
  /* Add the blur effect */
  filter: blur(4px);
  -webkit-filter: blur(4px);

  /* Full height */
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: absolute;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.jumbo-text {
  font-family: 700;
  z-index: 5;
  font-weight: 700 !important;
  max-width: 850px;
  color: white !important;
  opacity: 1;
}
.table-empty-placeholder-icon {
  width: 40%;
  max-width: 200px;
}
.session-card-title {
  z-index: 5000;
  font-weight: 700 !important;
  color: #f9f9f9 !important;
}
.request-cell {
  min-height: 120px;
  border-radius: 24px;
  box-shadow: 0 2px 8px #768a9520;
}
.status-icon {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #5d36bd;
  display: inline-block;
  position: relative;
  top: 1px;
}
.status-icon-full {
  background-color: #5d36bd;
}

.account-subscription-cell {
  height: 140px;
}

.account-subscription-cell-admin {
  height: 180px;
}
.user-admin-cell {
  min-height: 120px;
}
.user-profile-small {
  height: 60px;
  width: 60px;
  border-radius: 22px;
  object-fit: cover;
}
.badge-tags {
  background: #2b2b2b;
  color: white;
}
#button-to-create {
  position: absolute;
}
.share-session-url-button {
  height: 50px !important;
}
/* #nav-back-button {
  background: #ffffff;
  color: var(--primaryColor);
  border-color: #ffffff;
  display: block;
  /* vertical-align: baseline; */
/* position: absolute; */
/* float: start; */
/* } */

.clickable {
  cursor: pointer;
}

#save-paypal-details-button {
  float: right;
}

#pay-pay-email-input {
  width: 300px;
  display: block;
}

.cr-subtitle-text {
  font-size: 10px;
}

.cr-ad-user-name-label {
  font-size: 10px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 4px;
}

div.cr-input-field-container {
  padding-top: 4px;
  padding-bottom: 4px;
}

div.cr-nav-bar-bg {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  background-color: white;
  height: 50px;
}

body.create-body {
  background-color: #f3f3f3;
}

div.form-bg {
  margin-top: 24px;
  padding-left: 14px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f3f3f3;
}

.cr-nav-bar-title {
  margin-top: 24px;
  padding-left: 14px;
  padding-right: 14px;
}

div.cr-nav-bar-bg-master {
  line-height: 30px;
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  width: 100%;
  background-color: white;
  height: 50px;
}

#cr-nav-bar-account {
  margin: auto;
  width: auto;
  margin-top: 0px;
  padding-left: 14px;
  padding-right: 14px;
}

/*Scrolling cards */
.session-card-body {
  height: 200px;
  width: 100%;
  max-width: 420px;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* margin-left: 10px;
  margin-right: 10px; */
  flex: 0 0 auto;
}

.jumbo-body-container {
  max-height: 300px;
  height: 30%;
  overflow: hidden;
}
.jumbo-card-body {
  height: 90%;
  max-width: 1420px;
  max-height: 400px !important;
  min-width: 340px !important;
  width: 85%;
  /* flex: 0 0 auto; */
}

.post-card-body {
  height: 240px;
  width: 135px;
  border-radius: 8px;
}
.videobcg {
  width: inherit;
  height: inherit;
  -o-filter: blur(15px);
  filter: blur(15px);
  object-fit: cover;
  transform: scale(1.04);
}

.post-card-responsive {
  height: auto;
  width: 90%;
  border-radius: 8px;
}

.nav-bar-image {
  height: 40px;
  width: 100px;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: visible;
  overflow-y: hidden;
}

.scrolling-wrapper-card {
  height: 180px;
  width: 220px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 0 0 auto;
}

.pill-button {
  border-radius: 9999px !important;
}
.generic-action-button {
  height: 50px;
  font-family: 'Assistant', Roboto;
  font-size: 17px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}
.scrolling-wrapper-card-category {
  height: 180px;
  width: 100%;
  flex: 0 0 auto;
}

.to-hover {
  display: none !important;
}
.to-show:hover .to-hover {
  display: block !important;
}

.social-container-box-image {
  width: 24px;
  height: 24px;
  filter: invert(100%) sepia(74%) saturate(2%) hue-rotate(250deg)
    brightness(111%) contrast(101%);
}
.social-container-box-image-regular {
  width: 24px;
  height: 24px;
}
.scrolling-wrapper-card-big {
  height: 250px;
  width: 250px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 0 0 auto;
}

/* You can adjust the image size by increasing/decreasing the width, height */
.custom-circle-image {
  margin-top: 8px;
  width: 100px; /* note i used vw not px for better responsive */
  height: 100px;
}

.custom-circle-image-small {
  width: 70px; /* note i used vw not px for better responsive */
  height: 70px;
  object-fit: cover !important;
}

.card-bg {
  background-color: #f9f9f9 !important;
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}

.one-liner {
  margin-left: 4px;
  margin-right: 4px;
  width: auto; /* change this according to your layout */
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

.custom-circle-image img {
  object-fit: cover;
}

/* Changing Fonts ? */
html {
  font-family: 'Assistant', sans-serif;
}

body {
  font-family: 'Assistant', sans-serif;
}

.pm-bold {
  font-family: 'Assistant', sans-serif;
  font-weight: 700;
}
.session-card-user-name {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.session-card-user-title {
  font-size: 13px !important;
}

.pm-semibold {
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
}

.tooltip {
  font-family: 'Assistant', Roboto;
}

.popover {
  font-family: 'Assistant', Roboto;
}

.text-monospace {
  font-family: monospace;
}

.backButton {
  width: 30px !important;
  height: 30px !important;
  position: relative;
  background-color: white;
  border-radius: 15px;
  z-index: 1005;
}
.create-nav-bar-button {
  z-index: 1005;
  width: 80px;
  height: 40px;
  object-fit: contain;
}

.backButton:hover {
  background-color: gray;
  cursor: pointer;
}

.rightButton {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  z-index: 1005;
}

.rightButton:hover {
  background-color: gray;
  cursor: pointer;
}

.centered-img {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.transparent-button {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  position: absolute;
  outline: none;
}

.centered-overlay-button {
  max-height: 100px;
  max-width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.centered-overlay-button-small-top {
  max-height: 33px;
  max-width: 33px;
  position: absolute;
  top: 4px;
  left: 4px;
  margin: auto;
}

.modal-90w {
  width: 80%;
  min-width: 350px;
  height: 99%;
}
.centered-overlay-button-small {
  max-height: 33px;
  max-width: 33px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  margin: auto;
}
.scroll-vertically {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

/* Phone */
.input-group > .intl-tel-input.allow-dropdown {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
}

.input-group > .intl-tel-input.allow-dropdown > .flag-container {
  z-index: 4;
}
.PhoneInputInput {
  border: 0px;
  text-align: inherit;
  direction: ltr;
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.PhoneInputInput::placeholder {
  color: rgb(201 204 209);
}

.rtl .PhoneInputInput {
  text-align: right;
}

.iti-flag {
  background-image: url('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.6/img/flags.png');
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.6/img/flags@2x.png');
  }
}

.intl-tel-input {
  display: table-cell;
}
.intl-tel-input .selected-flag {
  z-index: 4;
}
.intl-tel-input .country-list {
  z-index: 5;
}
.input-group .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}

.full_modal-dialog {
  left: 0px;
  right: 0px;
  z-index: 2500;
  width: 100% !important;
  height: 100% !important;
  min-width: 100% !important;
  min-height: 100% !important;
  max-width: 98% !important;
  max-height: 100% !important;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0.3);
}

.full_modal-content {
  height: 99% !important;
  min-height: 99% !important;
  max-height: 99% !important;
}

/* Text height utility class */
.text-height-0 {
  line-height: 1 !important;
}
.text-height-1 {
  line-height: 1.5 !important;
}
.text-height-2 {
  line-height: 2 !important;
}
.text-height-3 {
  line-height: 2.5 !important;
}
.text-height-4 {
  line-height: 3 !important;
}
.text-height-5 {
  line-height: 3.5 !important;
}
.btn-separator:after {
  content: ' ';
  display: block;
  float: left;
  background: #adadad;
  margin: 0 10px;
  height: 34px;
  width: 1px;
}

#popover-menu {
  border: 0;
}

#popover-menu .arrow {
  display: none;
}

#popover-menu .popover-body {
  box-shadow: 0 4px 16px #768a9533;
  border-radius: 12px;
  padding: 10px 25px;
}

@media only screen and (max-width: 786px) {
  #popover-menu {
    position: fixed;
    top: 40px !important;
    bottom: 0 !important;
    height: 100%;
    left: 0 !important;
    width: 100vw;
    max-width: 100vw;
    z-index: 9999999999;
    margin-left: 0;
  }

  #popover-menu .popover-body {
    padding-bottom: 15px;
    box-shadow: none;
  }

  .popover {
    border-radius: 0 !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
}

.img-close {
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
}

.transaction-modal .modal-content {
  border-radius: 24px;
  padding: 20px;
}

.transaction-modal .custom-file {
  border-radius: 12px;
  border: 1px solid #e9ecef;
  overflow: hidden;
}

.transaction-modal .custom-file-label::after {
  padding: 0.375rem 25px;
}

.transaction-modal .custom-file-label {
  border: 0;
  padding-left: 10px;
}

.rtl-radio .custom-radio input {
  right: 0;
  left: unset;
  z-index: 1;
}

.rtl-radio .custom-radio label {
  margin-right: 25px;
}

.rtl-radio .custom-radio label::before {
  right: -25px;
  left: unset;
}

.video-react {
  padding-top: 0 !important;
}

.video-react-video {
  height: calc(100vh - calc(100vh - 100%));
  max-height: calc(100vh - calc(100vh - 100%));
}

.video-react-big-play-button {
  margin-top: 0 !important;
  height: 90px !important;
  width: 90px !important;
  border-radius: 50% !important;
  border: 0 !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.video-react .video-react-big-play-button.video-react-big-play-button-center {
  top: calc(50% - 45px) !important;
  z-index: 2;
}

.video-react-paused .video-react-big-play-button {
  display: initial !important;
}

.video-react:before {
  content: '';
  transition: 0.3s;
  opacity: 0;
  z-index: 1;
}

.video-react.video-react-paused:before {
  content: '';
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 1;
  opacity: 1;
}

.video-react .video-react-big-play-button:before {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}

.video-react .video-react-control-bar {
  z-index: 2;
}

.video-react .video-react-control-bar {
  bottom: 90px !important;
  display: flex !important;
}

.video-react .video-react-load-progress {
  opacity: 0 !important;
}

.video-react .video-react-progress-holder {
  height: 0.15em !important;
}

.video-react .video-react-play-progress {
  height: 0.15em !important;
}

.video-react .video-react-control-bar {
  background-color: transparent !important;
}

.video-react .video-react-progress-control {
  position: absolute !important;
  width: 100% !important;
  padding: 0 20px !important;
}

.video-react .video-react-play-control {
  position: absolute;
  bottom: 40px;
  left: 20px;
}

.video-react .video-react-volume-menu-button-horizontal {
  position: absolute !important;
  bottom: 40px;
  right: 30px;
}

.video-react .video-react-replay-control {
  display: none;
}

.video-react .video-react-forward-control {
  display: none;
}

.video-react .video-react-icon-fullscreen {
  display: none;
}

.video-react .video-react-time-divider {
  display: none;
}

.video-react .video-react-current-time {
  position: absolute !important;
  bottom: -12px;
  left: 16px;
  font-family: sans-serif;
}

.video-react .video-react-current-time .video-react-current-time-display {
  font-weight: 600;
}

.video-react .video-react-duration {
  position: absolute !important;
  bottom: -12px;
  right: 16px;
  font-family: sans-serif;
}

.video-react .video-react-duration .video-react-duration-display {
  font-weight: 600;
}

.video-react .video-react-play-control:before {
  font-size: 2.5em !important;
}

.video-react .video-react-volume-menu-button-horizontal:before {
  font-size: 2.4em !important;
}

.video-react .video-react-volume-menu-button-horizontal .video-react-menu {
  display: none !important;
}

.video-react .video-react-volume-menu-button-horizontal.video-react-vol-muted,
.video-react .video-react-volume-menu-button-horizontal.video-react-vol-3 {
  width: 40px !important;
  overflow: inherit;
}
.profile-video-background .action-btn-over {
  height: 60px !important;
}
