.root {
  padding-left: 8px;
  padding-right: 8px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 8px;

  img {
    background: none !important;
  }
}
