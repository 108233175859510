@import '../../../assets/scss/default/mixins';

.homepageContainer {
  background-color: #0e091e;
  min-height: 100vh;
  padding: 0;
  overflow: hidden;
}

.topCreatorsRanks {
  @media only screen and (min-width: 1200px) {
    & {
      columns: auto 3;
      column-gap: 50px;
    }
  }
}
