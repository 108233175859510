.Master {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.heb {
  text-align: right;
  direction: rtl;
}
.Container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 4px 16px rgba(157, 14, 40, 0.2);
  border-radius: 30px;
  max-width: 700px;
  height: 110px;

  .Icon {
    width: 62px;
    height: 62px;
  }

  .Title {
    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 6px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: -0.165px;

    color: #ffffff;
  }

  .Detail {
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
}

.Error {
  background: #df1338;
  box-shadow: 0px 4px 16px rgba(157, 14, 40, 0.2);
  border-radius: 30px;
}

.Warning {
  background: #fe7b16;
  box-shadow: 0px 4px 16px rgba(157, 14, 40, 0.2);
  border-radius: 30px;
}

.Success {
  background: #4cd964;
  box-shadow: 0px 4px 16px rgba(157, 14, 40, 0.2);
  border-radius: 30px;
}
