@import '../../../../assets/scss/default/theme';

.BookingSteps {
  max-width: 390px;
  width: 100%;
  height: 100%;
}

@media #{$media-phone} {
  .BookingSteps {
    margin-top: 10px;
    padding-bottom: 60px;
  }
}
