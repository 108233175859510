.main {
  background: rgba(0, 0, 0, 0.8);
  padding-right: 25px;
  padding-left: 25px;
  height: 50vh;

  img {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 30px;
    margin-right: 30px;
  }
}
.title {
  font-family: Montserrat, Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.165px;

  color: #ffffff;
}

.detail {
  padding-top: 15px;
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  text-align: center;

  color: #ffffff;
}
