.kr_input {
  /* Web/Paragraph 18 px */

  font-family: Assistant !important;
  font-style: normal;
  font-weight: 600;
  font-size: 38px !important;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.165px;

  /* Dark #111111 */

  color: #111111;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
