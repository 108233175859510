.regular {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
}
.dark {
  background: #0e091e !important;
}
.darkOpacity {
  background: rgba(14, 9, 30, 0.25);
}
.center {
  left: 50%;
  transform: translate(-50%, -50%);
}
.withoutBackground {
  background: none !important;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 123123;
}

.withBackground {
  border-radius: 25px;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  width: 150px;
  height: 150px;
  left: calc(50vw - 75px);
  top: calc(50vh - 75px);
}
