.creatorRankRowContainer {
  position: relative;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  margin: 20px 20px;
  cursor: pointer;
  &:hover {
    transition: transform 0.15s ease-in-out;
    transform: scale3d(1.05, 1.05, 1);
  }

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  &:first-child {
    margin-top: 0;
  }

  .creatorRankRow {
    display: flex;
    width: 100%;
  }

  .position {
    color: white;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 50px;
    margin-right: 15px;
    min-width: 25px;
  }

  .imgContinaer {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    overflow: hidden;
    background: linear-gradient(
      0deg,
      rgba(93, 54, 189, 0.2),
      rgba(93, 54, 189, 0.2)
    );

    img {
      width: 50px;
      height: 50px;
    }
  }

  .userDetails {
    span {
      display: block;
      //   color: white;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 1px;

      //   &:last-child {
      //     font-weight: 600;
      //     font-size: 0.75em;
      //     color: #dbcafe;
      //     margin-top: 0px;
      //   }
    }

    .title {
      font-size: 18px;
    }

    .detail {
      font-size: 14px;
    }
  }

  .rank {
    margin-left: auto;
    line-height: 50px;
    color: #ffc69c;

    svg {
      opacity: 0.5;
      margin-top: -3px;
      margin-right: 2px;
    }

    span {
      display: inline-block;
      font-size: 1em;
      font-weight: 700;
    }
  }
}
