@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.Master {
  height: 100vh;
  width: 100vw;
  background: white;

  .navbar {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .closeButton {
    text-align: center;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    background: #f9f9f9;
    img {
      width: 22px;
      height: 22px;
    }
  }

  .container {
    max-height: 812px;
    height: 100%;
    padding-bottom: 20px;
    max-width: $size-tablet;
    .topSection {
      .title {
        /* Web/Subtitle 24px */

        font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 110%;
        /* or 26px */

        color: #5d36bd;
      }

      .detail {
        /* Web/Paragraph 18 px */

        font-family: Assistant;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: -0.165px;

        color: rgba(17, 17, 17, 0.5);
      }
    }

    .idSection {
      position: relative;
      width: 335px;
      height: 520px;
      max-height: 520px;

      .backgroundLayer {
        position: absolute;
        border-radius: 26px;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-blend-mode: luminosity, overlay;
        mix-blend-mode: overlay;
        border: 2px solid #5d36bd;
        box-sizing: border-box;
        backdrop-filter: blur(40px);
        /* Note: backdrop-filter has minimal browser support */
        background: radial-gradient(
          170.49% 99.04% at 22.67% 11.94%,
          rgba(93, 54, 189, 0.4) 0%,
          rgba(202, 149, 111, 0.4) 57.98%,
          rgba(160, 57, 57, 0.4) 100%
        );

        .backgroundPicture {
          z-index: 2;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .foregroundLayer {
        background: transparent !important;
        z-index: 1000;
        height: 100%;
        border: 2px solid #5d36bd;
        border-radius: 26px;
        padding-top: 40px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .profilePicture {
          width: 50px;
          height: 50px;
          img {
            border-radius: 16px;
          }
        }
        .title,
        .detail {
          /* Web/Small heading 18px */

          font-family: Montserrat, Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.165px;

          color: #000000;
        }

        .qrCode {
          width: 200px;
          height: 200px;
        }

        .tag {
          font-family: Montserrat, Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 120%;
          /* or 19px */

          text-align: center;
          letter-spacing: -0.41px;

          color: #000000;
        }

        .infoContainer {
          /* Web/Sidenote 14 px */

          font-family: Assistant;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          /* or 18px */

          letter-spacing: -0.165px;

          color: #000000;
        }

        .shareButton {
          background: #5d36bd;
          border-radius: 16px;
          font-family: Assistant;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          height: 52px;
          width: 248px;
          line-height: 24px;
          /* identical to box height */

          text-align: center;
          letter-spacing: -0.165px;

          color: #ffffff;
        }
      }
    }

    .openButton {
      font-family: Assistant;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      /* identical to box height, or 14px */

      text-align: center;

      color: #5d36bd;

      background: #dbcafe;
      border-radius: 16px;
      width: 240px;
      height: 62px;
      border-radius: 16px;
      display: block;
      padding: 0px 20px;
      display: flex;
      justify-content: center;
      letter-spacing: 0em;
      text-align: center;
      color: #5d36bd;

      img {
        color: #5d36bd;
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }

      .heb {
        margin-left: 00px;
        margin-right: 10px;
      }
    }
  }
}

//TABLET
@media #{$media-tablet} {
}
//MOBILE
@media #{$media-phone} {
  .container {
    padding: 20px;
    .idSection {
      height: auto;
    }
  }
}
