@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.customDialog {
  background: transparent !important;
}

.modal {
  height: 100vh;
  width: 100vh;
  cursor: initial;
  transition: 0.2s all;
  top: 0px;
  left: 0px;
  z-index: 100000;
}

.wrapperHideEffect {
  transition: 0.3s all;
}
.wrapper {
  width: 100%;
  max-height: 90%;
  max-width: 1055px;
  left: 50%;
}
.wrapperBig {
  width: 1124px;
}
.wrapperScroll {
  left: 0;
  width: 100%;
  max-height: 90%;
  border-radius: 30px;
  background: white;
  overflow: scroll;
}
.close {
  width: 46px;
  height: 46px;
  cursor: pointer;
  background-image: url('./Images/close.svg');
  background-size: 100% 100%;
  transition: 0.3s all;
  z-index: 22;
  margin-right: 6px;
  margin-top: 6px;
  position: absolute;
  top: 0%;
  right: 0%;

  &:hover {
    transform: scale(1.05);
  }
}
.title {
  display: none;
}
.heb {
  .modal {
    font-family: 'Rubik', sans-serif;
  }
}
.closeIcon {
  display: none;
}
//TABLET
@media #{$media-tablet} {
  .wrapperScroll {
    left: 0;
    overflow: scroll;
  }
  .title {
    display: block;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    z-index: 2;
  }
  .title_icon {
    width: 36px;
    height: 5px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .wrapperScroll {
    max-height: 100vh;
    padding: 20px;
    border-radius: 24px 24px 0px 0px;
  }
  .close {
    display: none;
  }

  .wrapper,
  .wrapperBig {
    width: 100%;
    height: calc(100% - 52px);
    max-height: 100%;
    max-width: 100%;
    padding: 0px;
    border-radius: 0px;
    top: initial;
    margin-top: 52px;
    left: 0px;
  }
  .nonDraggable {
    border-radius: 24px;
    height: calc(100% - 30px);
    bottom: 15px !important;
    width: 94%;
    margin-left: 3%;

    .closeIcon {
      position: absolute;
      z-index: 9;
      right: 8px;
      top: 9px;
      cursor: pointer;
      display: inherit;
    }
  }
}

//MOBILE
@media #{$media-phone} {
  .customDialog {
    margin: 0px;
  }
  .wrapper {
    top: 0;
    margin-top: 0px;
    height: 100vh;
    padding-left: 00px;
    padding-right: 00px;
    min-height: 100vh;
    overflow: scroll;
  }
  .wrapperScroll {
    border-radius: 0px;
    bottom: 0;
    top: 0;
    left: 0;
    padding-bottom: 10px;
    min-height: 100vh;
  }
}
