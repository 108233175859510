.MasterContainer {
  min-height: 100vh;
}
.wrapper {
  min-height: calc(100vh - 70px) !important;
  padding: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tabs {
  width: 100%;
  overflow: scroll;
  display: flex;
  overflow: scroll;
  padding: 25px;
}
.tab {
  font-family: Montserrat, Rubik;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4099999964237213px;
  color: rgba(0, 0, 0, 0.2);
  margin-right: 25px;
  height: 20px;
  transition: 0.2s all;
  position: relative;
  top: 0px;
}
.tabSelected {
  color: black;
  font-family: Montserrat, Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.4099999964237213px;
  position: relative;
  top: -6px;
}
.spacer {
  width: 25px;
  opacity: 0;
}
.rows {
  width: 100%;
}
.row {
  width: 100%;
  display: flex;
  border-bottom: 1px #e3e3ee solid;
  padding: 16px 0px;
  justify-content: space-between;
  flex-wrap: wrap;

  //   &:first-child {
  //     padding-top: 0px;
  //   }
  //   &:last-child {
  //     border: none;
  //   }
}
.row_textWrapper {
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
}
.rowTitle {
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.4099999964237213px;
}
.rowSubTitle {
  font-family: Assistant;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin: 10px 0px;
  letter-spacing: 0em;
  color: rgb(93, 54, 189);
}
.rowLink {
  font-family: Assistant;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
}
.img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  //   margin-right: 15px;
}
.info {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.selectButton {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  margin-left: 20px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectButtonSelected {
  background: #5d36bd;
}
.buttonWrapper {
  margin: 20px 0px;
}
.button {
  height: 50px;
  width: 150px;
  text-align: center;
  background: #5d36bd;
  font-family: Assistant;
  border-radius: 18px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
}
.heb {
  direction: rtl;

  .img {
    margin-left: 15px;
    margin-right: 0px;
  }
  .info {
    margin-left: 10px;
    margin-right: 0px;
  }
  .selectButton {
    margin-left: 0px;
    margin-right: 20px;
  }
}
