@import '../../../assets/scss/default/theme';

.HowItWorks {
  padding-top: 0px;
  height: 100%;
}
.boxLight {
  background: white;
}
.boxDark {
  background: #231942;
}

.boxWrapper {
  padding: 20px;
  border-radius: 18px;
  margin-bottom: 10px;
}
.boxWrapperNoGutters {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 18px;
  margin-bottom: 10px;
}

.boxLineLight {
  width: 100%;
  height: 100%;
  border-left: 2px rgb(93, 54, 189) solid;
  padding-left: 20px;
  color: black;
}
.boxLineDark {
  width: 100%;
  height: 100%;
  border-left: 2px #dbcafe solid;
  padding-left: 20px;
  color: black;
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}
.subTitle {
  font-family: 'Assistant';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16500000655651093px;
  margin-top: 8px;
}

.titleDark {
  color: white;
  font-family: Montserrat, Rubik;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}
.subTitleDark {
  color: #cfcfcf;
  font-family: 'Assistant';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16500000655651093px;
  margin-top: 8px;
}

.close {
  display: none;
}
.heb {
  direction: rtl;

  .box {
    border-left: none;
    padding-left: 0px;
    padding-right: 20px;
    border-right: 2px rgb(93, 54, 189) solid;
  }
}
@media only screen and (max-width: 1024px) {
  .HowItWorks {
    height: 100%;
  }
  .boxWrapper {
    box-shadow: 0px 4px 20px 0px rgba(118, 138, 149, 0.2);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .close {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    left: 50%;
  }
}

@media #{$media-laptop} {
  .HowItWorks {
    height: 100%;
    padding-top: 20px;
  }
  .boxWrapper {
    box-shadow: 0px 4px 20px 0px rgba(118, 138, 149, 0.2);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .close {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    left: 50%;
  }
}

@media #{$media-phone} {
  .HowItWorks {
    height: 100vh;
    padding-top: 20px;
  }
}
