.wrapper {
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.text {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  margin: 20px 0px;
}
.category {
  width: 100%;
  margin-bottom: 20px;
}
.title {
  width: 100%;
  font-family: Montserrat, Rubik;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 10px;
}
.tags {
  width: calc(100% + 10px);
  display: flex;
  flex-wrap: wrap;
}
.tag {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgb(93, 54, 189);
  padding: 8px 15px;
  border-radius: 16px;
  margin-right: 10px;
  background: #f9f9f9;
  margin-bottom: 10px;
  transition: 0.2s all;

  img {
    margin-right: 10px;
  }
}
.active {
  color: white;
  background: #5d36bd;
}
.save {
  padding: 13px 70px;
  color: white;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  background: #5d36bd;
  border-radius: 18px;
}
.wrapper {
  width: 100%;
  max-width: 1100px;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}

.heb {
  direction: rtl;
  .title {
    font-family: 'Rubik', sans-serif;
  }

  .tag {
    margin-right: 0px;
    margin-left: 10px;
  }
}
