@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../assets/scss/default/theme';

//DESKTOP
.master {
  background: white;
  position: relative;
  display: flex;
  height: 100%;
  max-height: 90%;
  padding-left: 40px;
  padding-right: 40px;
}

@media #{$media-tablet} {
  .master {
    height: calc(100vh - 80px);
    padding: 20px;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media #{$media-phone} {
  .master {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh;
    bottom: 0;
    max-height: 100%;
  }

  .dialog {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    margin: 0px !important;
    background: white !important;
    padding: 0px;
  }
}
