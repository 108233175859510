@import '../../../../assets/scss/default/theme';

.BookingProfile {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-bottom: 40px;
}
.wrapper {
  height: 100%;
  width: 280px;
}
.title {
  width: 100%;
  text-align: center;
  font-family: Montserrat, Rubik;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.41px;
  color: #111111;
}
.tag {
  margin-top: 80px;
  width: 100%;
  text-align: center;
  font-family: 'Assistant';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  display: flex;
  color: #5d36bd;
}
.profilePicLoading {
  overflow: hidden;
}
.profilePic {
  margin: 20px 0px;

  .profilePic_img {
    width: 208px;
    height: 208px;
    object-fit: cover;
    border-radius: 26%;
  }
}
.stars {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
  background: #5d36bd;
  color: white;
  border: 4px #f9f9f9 solid;
  font-family: Montserrat, Rubik;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: absolute;
  left: 50;
  bottom: -10px;
  margin-left: 200px;
  right: auto;
}
.wrapperDown {
  height: 75%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.starIcon {
  width: 8px;
  margin-right: 0px;
  margin-bottom: 1px;
  margin-left: 0px;
}
.name {
  width: 100%;
  font-family: Montserrat, Rubik;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
  color: rgb(17, 17, 17);
  display: flex;
  justify-content: center;
}
.subName {
  width: 100%;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
}
.checkmark {
  margin-left: 10px;
}
.responseTime {
  margin-top: 33px;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
  color: #5d36bd;
}
.responseTime_answer {
  font-family: 'Assistant';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
  color: #5d36bd;
}
.noRows {
  display: flex !important;
  border-radius: 0px !important;
}
.heb {
  .stars,
  .title,
  .name {
    font-family: 'Rubik', sans-serif;
    direction: rtl;
  }
  .checkmark {
    margin-right: 10px;
    margin-left: 0px;
  }
  .responseTime_answer,
  .tag {
    direction: rtl;
  }
  .title,
  .stars,
  .name {
    font-family: 'Rubik', sans-serif;
  }
}

@media #{$media-laptop} {
  .stars {
    width: 42px;
    height: 42px;
    font-size: 10px;
    line-height: 31px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    left: 50;
    margin-left: 180px;
    right: auto;
  }
}
@media #{$media-tablet} {
  .tag {
    margin-top: 0px;
  }
  .wrapperDown,
  .wrapper {
    height: auto;
  }

  .profilePic {
    .profilePic_img {
      width: 104px;
      height: 104px;
    }
  }

  .stars {
    width: 42px;
    height: 42px;
    font-size: 10px;
    line-height: 31px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    left: 50;
    margin-left: 70px;
    right: auto;
  }
  .title,
  .name {
    font-size: 24px;
  }
  .checkmark {
    width: 18px;
  }
  .responseTime {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    margin-top: 0px;
  }
  .responseTime_answer {
    display: none;
  }
}

@media #{$media-phone} {
  .profilePic {
    .profilePic_img {
      width: 104px;
      height: 104px;
    }
  }

  .title {
    font-size: 24px;
  }
  .tag {
    margin-top: 16px;
    font-size: 16px;
  }
  .stars {
    width: 40px;
    height: 40px;
    font-size: 10px;
    line-height: 33px;
    border: 3px #f9f9f9 solid;
    bottom: -10px;
    left: 50;
    margin-left: 100px;
    right: auto;
  }
  .name {
    font-size: 24px;
  }
  .checkmark {
    width: 28px;
  }
  .subName {
    font-weight: 600;
    font-size: 16px;
    margin-top: 0;
  }
  .responseTime {
    margin-top: 10px;
  }
}
