@import '../../../assets/scss/default/mixins';
@import '../../../assets/scss/default/theme';

.Master {
  width: 100%;
  height: auto;

  .customDialog {
    background: white !important;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    padding-bottom: 20px;
    width: 100%;
    height: 264px;
    background-color: white;
    border-radius: 30px;

    .title {
      /* Web/Subtitle 24px */

      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 110%;
      /* or 26px */

      text-align: center;

      color: #5d36bd;
    }

    .input {
      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height */

      letter-spacing: -0.165px;

      color: #5d36bd;
    }

    .button {
      height: 50px;
      border-radius: 25px;
      background: #5d36bd;
      border-radius: 100px;
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.165px;

      color: #ffffff;
    }
  }
}
