.buttons {
  background: rgb(249, 249, 249);
}
.whiteBackground {
  height: 30px;
  background: white;
  border-radius: 0px 0px 30px 30px;
}
.wrapper {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.button {
  height: 120px;
  max-width: 220px;
  cursor: pointer;
  margin-bottom: 15px;
  width: calc(50% - 8px);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 768px) {
    max-width: none;
  }
}
.toggle {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
}
.icon {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 4px 16px rgba(44, 18, 107, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom {
  display: flex;
  justify-content: space-between;
  color: white;
}
.text {
  font-family: 'Assistant';
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.16500000655651093px;
}
.button1 {
  background: #5d36bd;
}
.button2 {
  background: #4bd7b5;
}
.button3 {
  background: #ee665d;
}
.button4 {
  background: #4d76e1;
}
.button5 {
  background: #ab97db;
}
.button6 {
  background: #f89a51;
}
.button7 {
  background: #89bbf8;
}
.button8 {
  background: #829cb5;
}

.heb {
  .toggle {
    right: initial;
    left: 15px;
  }
  .bottom img {
    transform: rotate(180deg);
  }
}
