@import '../../../assets/scss/default/mixins';
@import '../../../assets/scss/default/theme';

.breadcrumbContainer {
    max-width: $max-page-width;
    margin: 25px auto;

    @media #{$media-phone} {
        padding: 0 20px;
      }

    span {
        color: #888888;
        font-size: 0.9em;
        font-weight: 600;
    }

    .home {
        color: #CFCFCF;
    }

    .separator {
      margin-left: 10px;
      margin-right: 10px;
    }
}