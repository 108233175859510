@import '../../assets/scss/default/mixins';
@import '../../assets/scss/default/theme';

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;

  &.dark {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    background: #0e091e;
  }

  h6 {
    color: rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 140%;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    margin: 20px 0;
  }

  li {
    color: white;
    font-weight: bold;
    line-height: 130%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .centerInMobile {
    text-align: center;
  }
  h6 {
    text-align: center;
  }
  li {
    text-align: center;
  }
  p {
    text-align: center;
  }

  // .centerInMobileLogoContainer {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;
  // }
}

.logo {
  background: url('../NewNavbar/logo.svg') center center no-repeat;
  width: auto;
  height: 25px;
}

.terms {
  a {
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
    opacity: 0.3;
  }
}

.dark {
  background: transparent;
  margin-bottom: 0px;

  h6 {
    color: rgba(255, 255, 255, 0.3);
  }

  p {
    direction: ltr;
    color: rgba(255, 255, 255, 0.6);
  }

  a {
    color: white;
  }

  .logo {
    background: url('../NewNavbar/logoWhite.svg') center center no-repeat;
  }

  // .centerLogo {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;
  // }
}
