@import '../../../assets/scss/default/mixins';
@import '../../../assets/scss/default/theme';

.EarnCredit {
  width: 100%;
  background: rgb(249, 249, 249);
  height: 90vh;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;
  max-width: 1056px;
  max-height: 839px;
}

.codeDesktop {
  display: flex !important;
}
.codeMobile {
  display: none !important;
}
.noBackground {
  background: none;
}
.sides {
  width: 100%;
  height: calc(100% - 160px);
  align-items: center;
}
.wrapper {
  width: 100%;
}
.side {
  width: 50%;
  padding: 0px 40px;
  box-sizing: border-box;
}
.title {
  font-family: Montserrat, Rubik;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -0.4099999964237213px;
  color: rgb(93, 54, 189);
}
.subTitle {
  font-family: Montserrat, Rubik;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.4099999964237213px;
  color: black;
  margin-top: 8px;
}
.text {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: left;
  color: black;
  margin: 40px 0px;
}
.code {
  display: none;
  text-transform: lowercase;
  padding: 19px 25px;
  background: white;
  border: 1px solid rgb(219, 202, 254);
  border-radius: 12px;
  color: black;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: left;
}
.copy {
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    transform: scale(1.05);
  }
}
.img {
  margin-top: 8px;
}
.copied {
  transition: 0.2s all;
  cursor: pointer;

  &:hover {
    color: rgb(93, 54, 189);
  }
}
.referralTitle {
  margin-top: 134px;
  font-family: 'Assistant';
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: black;
}
.referralCode {
  margin-top: 20px;
  font-family: 'Assistant';
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.15em;
  text-align: center;
  color: black;
}
.button {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  background: #5d36bd;
  color: white;
  border-radius: 21px;
  padding: 20px 80px;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    color: white;
    transform: scale(1.05);
  }
}
.heb {
  direction: rtl;
  justify-content: space-evenly;

  .title,
  .subTitle {
    font-family: 'Rubik', sans-serif;
  }

  .text {
    text-align: right;
  }
  .wrapper {
    flex-direction: row-reverse;
  }
  .referralCode {
    margin-top: 0px;
  }
}
//tablet
@media #{$media-tablet} {
  .codeDesktop {
    display: none !important;
  }
  .codeMobile {
    display: flex !important;
  }
  .EarnCredit {
    width: 100%;
    background: white;
    padding: 40px 0px;
  }
  .wrapper {
    flex-direction: column;
  }
  .title {
    font-size: 24px;
    line-height: 29px;
  }
  .button {
    margin-top: 20px;
  }
  .sides {
    height: auto;
  }
  .img {
    transform: translateX(-50%);
    max-width: 140px;
    position: relative;
    left: 50%;
  }
  .heb {
    .img {
      transform: translateX(50%);
      left: initial;
      right: 50%;
    }
  }
  .side {
    width: 100%;
    padding: 0px;
  }
  .subTitle {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
  }
  .text {
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0px;
  }
  .referralTitle {
    margin-top: 30px;
    font-size: 14px;
  }
  .code {
    margin-top: 20px;
    border: none;
    background: rgb(249, 249, 249);
  }
}

//phone
@media #{$media-phone} {
  .codeDesktop {
    display: none !important;
  }
  .codeMobile {
    display: flex !important;
  }
  .EarnCredit {
    background: white;
    padding: 40px 0px;
  }
  .wrapper {
    flex-direction: column;
  }
  .title {
    font-size: 24px;
    line-height: 29px;
  }
  .button {
    margin-top: 20px;
  }
  .sides {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .img {
    transform: translateX(-50%);
    max-width: 140px;
    position: relative;
    left: 50%;
  }
  .heb {
    .img {
      transform: translateX(50%);
      left: initial;
      right: 50%;
    }
  }
  .side {
    width: 100%;
    padding: 0px;
  }
  .subTitle {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
  }
  .text {
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0px;
  }
  .referralTitle {
    margin-top: 30px;
    font-size: 14px;
  }
  .code {
    margin-top: 20px;
    border: none;
    background: rgb(249, 249, 249);
  }
}
