.homepageSectionContainer {
  padding: 20px 0;
  max-width: 1240px;
  margin: auto;

  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 30px;
    padding: 0 20px;

    h6 {
      font-weight: bold;
      font-size: 18px;

      @media only screen and (min-width: 767px) {
        & {
          font-size: 22px;
        }
      }
    }

    a {
      color: #898a8d;
      font-size: 1em;
      font-weight: 600;
    }
  }

  .sectionContent {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .sectionContentDiscover {
    // text-align: center;
    white-space: normal;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
