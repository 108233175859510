.rankBadgeContainer {
    width: 32px;
    height: 32px;
    background-color: #5D36BD;
    border: 2px solid #221C34;
    font-size: 0.65em;
    border-radius: 50%;
    color: white;
    display: flex;

    svg {
        margin-top: 10px;
        margin-left: 2px;
        margin-right: 1px;
        font-size: 0.65em;
    }

    span {
        color: white;
        display: inline-block;
        margin: 0;
        line-height: 28px;
        font-weight: 600;
    }
}