.text{
    font-family: 'Assistant';
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.16500000655651093px;
    color: rgba(0,0,0,0.4);
}
.box {
    border-radius: 23px;
    background: rgb(249,249,249);
    padding:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:40px;
}
.title {
    font-family: 'Assistant';
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
}
.addLink{
    display: flex;
    align-items: center;
}
.img{
    margin:40px 0px 80px 0px;
    position: relative;
    left:-5px; 
}
.addText{
    font-family: 'Assistant';
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    margin:0px 15px;
    color: rgb(93,54,189);
}