@import '../../../../assets/scss/default/mixins';
@import '../../../../assets/scss/default/theme';

//DESKTOP
.Master {
  width: 100%;
  height: 100%;

  .navbar {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .contentContainer {
    height: 100%;
  }

  .closeButton {
    text-align: center;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    background: #f9f9f9;
    img {
      width: 22px;
      height: 22px;
    }
  }

  > .title {
    /* Web/Subtitle 24px */

    width: 75%;
    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    /* or 26px */

    color: #111111;
    margin-bottom: 16px;
  }

  .OneTimePurchaseView {
    border: 1px solid #5d36bd;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 20px;

    .picture {
      border-radius: 26px;
      width: 52px;
      height: 52px;
    }

    .title {
      /* Web/Paragraph 18 px */

      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: -0.165px;

      color: #000000;
    }

    .detail {
      /* Web/Paragraph 18 px */

      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: -0.165px;

      color: #000000;
    }

    .priceTitle {
      /* Web/Big numbers 24px */
      margin-top: 20px;
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      /* or 29px */

      text-align: center;
      letter-spacing: -0.41px;

      color: #5d36bd;
    }

    .priceDetail {
      /* Web/Sidenote 14 px */

      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      /* or 18px */

      text-align: center;
      letter-spacing: -0.165px;

      color: #000000;

      opacity: 0.5;
    }
  }

  .pricingOptions {
    min-height: 150px;
    margin-top: 30px;
    margin-bottom: 30px;

    .title {
      font-family: Assistant;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      /* or 18px */

      letter-spacing: -0.165px;

      color: #ffc69c;
    }

    .originalPrice {
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      letter-spacing: -0.165px;
      text-decoration-line: line-through;

      color: #ffffff;

      opacity: 0.5;
    }

    .discount {
      margin-left: 4px;
      margin-right: 4px;
      font-family: Montserrat, Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      color: #ffc69c;

      opacity: 1;
    }

    .active {
      padding: 20px;
      background: #5d36bd;
      border-radius: 16px;

      div {
        height: 100%;
        display: flex;
        justify-content: space-between;
      }

      .price {
        /* Web/Big numbers 24px */

        font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 29px */

        letter-spacing: -0.41px;

        color: #ffffff;

        text-transform: uppercase;
      }
      .period {
        line-height: 100%;
        font-family: Assistant;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 17px */

        letter-spacing: -0.41px;

        color: #ffffff;
      }
    }

    .notActive {
      justify-content: flex-end;
      padding: 15px;
      background: #f9f9f9;
      border-radius: 16px;

      .discount {
        display: none;
      }

      .price {
        /* Web/Big numbers 24px */

        font-family: Montserrat, Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        /* identical to box height, or 29px */

        letter-spacing: -0.41px;

        color: #111111;
        text-transform: uppercase;
      }
      .period {
        /* Web/Sidenote 14 px */

        font-family: Assistant;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        /* or 18px */

        letter-spacing: -0.165px;

        color: #111111;
      }
    }
  }
  .couponSection {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    /* Web/Small button 16px */

    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* or 19px */

    color: #5d36bd;
  }

  .active {
    color: $purple !important;
  }
  .confirmTitle {
    /* Web/Small heading 18px */

    font-family: Montserrat, Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.165px;
  }
  .cardInput {
    margin-top: 20px;
    min-height: 100px;
    direction: ltr !important;
    .input {
      font-family: Assistant;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
    }

    .containerClassName {
      width: '100%';
      border-bottom: 2px solid $purple-washed;
      border-radius: 1px;
      direction: ltr !important;
    }
  }
  .infoLabel {
    /* Web/Sidenote 14 px */
    margin-bottom: 40px;
    font-family: Assistant;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* or 18px */

    text-align: center;
    letter-spacing: -0.165px;

    color: rgba(17, 17, 17, 0.5);

    span {
      /* Web/Sidenote 14 px */

      font-weight: bold;

      color: #5d36bd;
    }
  }
  .confirmButton {
    width: 240px;
    text-transform: none;
    background: #5d36bd;
    border-radius: 16px;
    height: 62px;
    background-color: $purple;
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.165px;

    color: #ffffff;
  }
  .cancelButton {
    /* Web/Big button 18px */
    margin-top: 10px;
    height: 62px;
    font-family: Assistant;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* identical to box height, or 22px */

    text-align: center;
    letter-spacing: -0.41px;

    color: #5d36bd;
  }
}
//TABLET
@media #{$media-tablet} {
}
//MOBILE
@media #{$media-phone} {
  .Master {
    > .title {
      width: 95%;
    }
  }
}
