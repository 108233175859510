@import '../../../../assets/scss/default/theme';

.BookingSteps {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  position: relative;
}
.line {
  height: 2px;
  width: 100%;
  background: #e3e3ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 8px;
}
.dot {
  width: 18px;
  height: 18px;
  border: 2px #e3e3ee solid;
  border-radius: 50%;
  background: white;
  position: relative;
}
.dotActive {
  border: 5px #5d36bd solid;
}
.imageFinished {
  display: none;
}
.finished {
  background: #5d36bd;
  border: 2px #5d36bd solid;
  display: flex;
  justify-content: center;
  align-items: center;

  .imageFinished {
    display: block;
  }
}
.tooltip {
  width: 85px;
  background: white;
  box-shadow: 0px 4px 20px rgba(118, 138, 149, 0.2);
  position: absolute;
  color: rgb(93, 54, 189);
  padding: 7px 15px;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -55px;

  &:after {
    display: inline-block;
    vertical-align: 0.255em;
    content: '';
    border-top: 8px solid;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-left: 8px solid transparent;
    color: white;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: -8px;
  }
}
.heb {
  direction: rtl;
}
@media #{$media-tablet} {
  .BookingSteps {
    width: 80%;
    margin-bottom: 10px;
    position: relative;
    left: 10%;
  }
  .tooltip {
    width: 70px;
    padding: 4px 15px;
    font-size: 12px;
    top: -41px;
  }
}

@media #{$media-phone} {
  .BookingSteps {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    position: relative;
  }
}
