.model {
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.title {
  margin-top: 60px;
  color: rgba(254, 123, 22, 1);
  font-weight: 700;
  font-size: 32px;
  font-family: Montserrat, Rubik;
}
.subTitle {
  font-family: Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.165px;

  color: #5d36bd;
}
.text {
  font-family: 'Assistant';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  color: rgb(17, 17, 17);
  margin-top: 20px;
}
.img {
  width: 250px;
}
.button {
  padding: 20px 100px;
  color: white;
  background: #5d36bd;
  border-radius: 21px;
  font-family: 'Assistant';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  text-align: center;
  margin-top: 20px;
}
.heb {
  .title,
  .text {
    direction: rtl;
  }
  .title {
    font-family: 'Rubik', sans-serif;
  }
}
@media only screen and (max-width: 1024px) {
  .title {
    text-align: center;
    line-height: 120%;
  }
  .model {
    padding: 60px 0px;
  }
  .img {
    width: 80px;
  }
}
