.categoryContainer {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 70px;

  &:hover {
    transition: transform 0.15s ease-in-out;
    transform: scale3d(1.05, 1.05, 1);
  }
  background: linear-gradient(
    0deg,
    rgba(93, 54, 189, 0.2),
    rgba(93, 54, 189, 0.2)
  );
  border-radius: 16px;
  margin-right: 15px;
  overflow: hidden;
  cursor: pointer;

  &:first-child {
    margin-left: 20px;
  }

  .heb {
    margin-left: 15px;
    &:first-child {
      margin-right: 20px;
    }
  }

  .titleContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 767px) {
    & {
      width: 160px;
      height: 70px;
    }
  }

  .imageContainer {
    figure {
      width: 100%;
      height: 70px;

      @media only screen and (min-width: 767px) {
        & {
          height: 70px;
        }
      }
    }
  }

  .imageContainer::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(
      0deg,
      rgba(93, 54, 189, 0.2),
      rgba(93, 54, 189, 0.2)
    );
  }

  span {
    display: block;
    text-align: center;
    // line-height: 113px;
    color: white;

    font-weight: bold;
    position: absolute;
    width: 100%;

    @media only screen and (min-width: 767px) {
      & {
        line-height: 160px;
        font-size: 1em;
      }
    }
  }

  & a:last-child {
    margin-right: 20px;
  }
}

.categoryContainerDiscover {
  width: calc(50% - 15px);
  margin-bottom: 10px;

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(even) {
    margin-right: 0;
  }

  @media only screen and (min-width: 767px) {
    & {
      width: calc(25% - 15px);
    }

    &:nth-child(even) {
      margin-right: 15px;
    }

    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}
