.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainImage {
  width: 153px;
  height: 153px;
  margin: 10px 0px 40px 0px;
  position: relative;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.mainImage_text {
  width: 100%;
  font-family: 'Assistant';
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: rgb(93, 54, 189);
  position: absolute;
  top: calc(50% + 4px);
}
.input {
  width: 100%;
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  color: rgb(0, 0, 0);
  padding-bottom: 9px;
  margin-bottom: 30px;
  border: none;
  outline: none;
  border-bottom: 2px rgba(93, 54, 189, 0.3) solid;

  &::placeholder {
    font-family: 'Assistant';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16500000655651093px;
    color: rgba(0, 0, 0, 0.2);
  }
}
.selectList {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  color: rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.selectedType {
  color: black;
}
.button {
  width: 100%;
  padding: 14px 0px;
  background: #dbcafe;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 18px;
  color: white;
  font-family: 'Assistant';
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.165px;
  margin-top: 40px;
}
.button_active {
  background: #5d36bd;
}
.error {
  margin-top: 4px;
  text-align: center;
  color: red;
}
.fromLabel {
  width: 100%;
  display: flex;
}
.label {
  font-family: 'Assistant';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16500000655651093px;
  margin-right: 13px;
}
.heb {
  .label {
    width: 80px;
  }
}
